<div>

    <div class="panel">

        <div class="panel-hdr">
            <h2>Developer</h2>
        </div>
        <div class="panel-container">
            <div class="panel-content">

                <dl>
                    <dt>Token</dt>
                    <dd>
                      {{ token | slice:0:12 }}
                    </dd>
                </dl>
                <button (click)="addLoan()">+ Loan</button>
                <button (click)="addTaxes()">+ Taxes</button>
            </div>
        </div>
    </div>

</div>