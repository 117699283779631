import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoanFormComponent } from './loan-form/loan-form.component';
import { LoanStatsComponent } from './loan-stats/loan-stats.component';
import { MainComponent } from './main/main.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginModalComponent } from './login/login-modal.component';
import { LanguageComponent } from './language/language.component';
import { MaterialModule } from '@app/material.module';
import { LoanDetailComponent } from './loan-detail/loan-detail.component';
import { LoanHeaderComponent } from './loan-header/loan-header.component';
import { GridModule } from '../grid/grid.module';
import { LoanUpdatesComponent } from './loan-updates/loan-updates.component';
import { DevFrameComponent } from './dev-frame/dev-frame.component';
import { TransactionModalComponent } from './transaction-modal/transaction-modal.component';
import { ScenarioHeaderComponent } from './scenario-header/scenario-header.component';
import { ElementFormComponent } from './element-form/element-form.component';
import { ScenarioDetailComponent } from './scenario-detail/scenario-detail.component';
import { GridScenarioModule } from '../grid-scenario/grid-scenario.module';
import { DetailFrameComponent } from './detail-frame/detail-frame.component';
import { XEditableModule } from '../x-editable/x-editable.module';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    GridModule,
    GridScenarioModule,
    XEditableModule,
    LanguageComponent
  ],
  declarations: [
    MainComponent,
    LoanFormComponent,
    ElementFormComponent,
    ScenarioDetailComponent,
    ScenarioHeaderComponent,
    LoanStatsComponent,
    DetailFrameComponent,
    HeaderComponent,
    FooterComponent,
    LoginModalComponent,
    TransactionModalComponent,
    LoanDetailComponent,
    LoanHeaderComponent,
    LoanUpdatesComponent,
    DevFrameComponent,
  ],
  exports: [
    LoanFormComponent,
    ElementFormComponent,
    LoanStatsComponent,
    ScenarioDetailComponent,
    ScenarioHeaderComponent,
    HeaderComponent,
    DetailFrameComponent,
    FooterComponent,
    LoginModalComponent,
    TransactionModalComponent,
    LoanDetailComponent,
    LoanHeaderComponent,
    LoanUpdatesComponent,
    DevFrameComponent
  ]
})
export class LayoutModule {}
