import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap, filter, map, exhaustMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';

import { AuthState } from './auth.reducer';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@models';
import { of } from 'rxjs';
import * as AuthActions from './auth.actions';
import { AuthService } from '@app/services/auth.service';
import { AuthTokenService } from '@app/services/auth-token.service';
import { getUser } from './auth.selectors';

@Injectable()
export class AuthEffects {
  redirectUrl = '/';
  dashUrl = '/dashboard';

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LoginAction),
    map(action => action.payload),
    exhaustMap((auth: User) => this.authService.login(auth).pipe(
      map(payload => AuthActions.AuthUserChange({ payload })),
      catchError(error => of(AuthActions.AuthFailure({ error })))
    ))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LogoutAction),
    tap(_ => this.authToken.token = undefined),
    concatMap(() => [
      AuthActions.NullToken(),
      AuthActions.LoginRedirect({ url: this.redirectUrl })
    ])
  ));

  authRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthTokenPayload, AuthActions.UserAccountSuccess),
    filter(() => this.router.url === this.dashUrl || this.router.url === this.redirectUrl),
    tap(() => this.router.navigate([this.dashUrl]))
  ), { dispatch: false });

  loginRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LoginRedirect),
    tap((data: any) => this.router.navigate([this.redirectUrl]))
  ), { dispatch: false });

  authUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthUserChange),
    tap(_ => (this.authToken.token = _)),
    map(_ => this.authToken.readPayload(_)),
    map(_ => AuthActions.AuthTokenPayload(_))
  ));

  loggedOnce$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LoggedOnce),
    // tap(() => console.log('loggedOnce effect', this.authToken.token)),
    filter(() => this.authToken.token !== undefined),
    map(() => AuthActions.UserAccount())
  ));

  userAccount$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.UserAccount),
    withLatestFrom(this.store.select(getUser)),
    concatMap(([_, usr]) => this.authService.account(usr.id).pipe(
      map(user => AuthActions.UserAccountSuccess(user)),
      catchError(error => of(AuthActions.UserAccountFailure({ error })))
    ))
  ));

  dispatchError = (error: any) => {
    this.store.dispatch(
      AuthActions.AuthFailure({ error })
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private authToken: AuthTokenService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
}
