import * as fromLoan from './loan.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const getLoanState = createFeatureSelector<fromLoan.LoanState>('loan');

export const getColumnLoading = createSelector(getLoanState,
  (state: fromLoan.LoanState) => state.loading);

export const getScenarios = createSelector(getLoanState, fromLoan.selectAll);

