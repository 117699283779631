import { User } from '@models';
import { createAction, props } from '@ngrx/store';

export const AppInit = createAction(
  '[App] Init'
);

export const AuthInit = createAction(
  '[Auth] Init'
);

export const LoggedOnce = createAction(
  '[Auth] Logged Once',
  props<{ payload: boolean }>()
);

export const LoginAction = createAction(
  '[Auth] Login Action',
  props<{ payload: any }>()
);

export const LogoutAction = createAction(
  '[Auth] Logout Action'
);

export const LoginRedirect = createAction(
  '[Auth] Login Redirect Action',
  props<{ url: string }>()
);

export const SignupAction = createAction(
  '[Auth] Signup Action',
  props<{ payload: any }>()
);

export const GoogleSign = createAction(
  '[Auth] Google Sign Action'
);

export const AuthFailure = createAction(
  '[Auth] Failure Action',
  props<{ error: any }>()
);

export const AuthUserChange = createAction(
  '[Auth] User Change',
  props<{ payload: User }>()
);

export const AuthSuccess = createAction(
  '[Auth] Login Success',
  (user: User) => ({ user })
);

export const AuthTokenPayload = createAction(
  '[Auth] Token Payload',
  ( payload: any ) => ({ uid: payload.user_id, ...payload })
);

export const NullToken = createAction(
  '[Auth] Null Token'
);

export const TokenRestore = createAction(
  '[Auth] Token Restore',
  props<{payload: any}>()
);

export const TokenRefresh = createAction(
  '[Auth] Token Refresh'
);

export const TokenRefreshSuccess = createAction(
  '[Auth] Token Refresh Success',
  props<{ payload: any }>()
);

export const UserAccount = createAction(
  '[Auth] User Account'
);

export const UserAccountSuccess = createAction(
  '[Auth] User Account Success',
  (user: User) => ({ user })
);

export const UserAccountFailure = createAction(
  '[Auth] User Account Failure',
  props<{ error: any }>()
);

export const AuthActionTypes: string[] = [
  AppInit.type,
  AuthInit.type,
  LoggedOnce.type,
  LoginAction.type,
  LogoutAction.type,
  LoginRedirect.type,
  SignupAction.type,
  GoogleSign.type,
  AuthSuccess.type,
  AuthFailure.type,
  AuthUserChange.type,
  AuthTokenPayload.type,
  NullToken.type,
  TokenRestore.type,
  TokenRefresh.type,
  TokenRefreshSuccess.type,
  UserAccount.type,
  UserAccountSuccess.type,
  UserAccountFailure.type
];

