<div class="panel" id="hipo_details" *ngIf="loanOrScenario()">

    <div class="panel-hdr">
        <ul class="nav nav-tabs nav-tabs-clean ">
            <li class="nav-item text-left"><a class="nav-link title"><h2>{{ 'L_AMORT_TITLE' | translate }}</h2></a></li>
            <li class="nav-item push">
                <a class="nav-link" [ngClass]="{'active': onScenario}" (click)="toggle(true)">Scénario</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': !onScenario}" (click)="toggle(false)">Hypothèque</a>
            </li>
        </ul>
    </div>
    <div role="content">

        <div class="no-padding">

            <app-scenario-detail [style.display]="scenarioVisible()" *ngIf="(scenario$ | async)"></app-scenario-detail>
            <app-loan-detail [style.display]="loanVisible()" *ngIf="(loan$ | async)"></app-loan-detail>
            
        </div>

        <div id="board_range" class="navig-inline"></div>

    </div>
</div>