import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { HypoService } from '@app/services/hypo.service';
import { Store } from '@ngrx/store';

@Injectable()
export class ScenarioEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private hypoService: HypoService
    ) { }
}
