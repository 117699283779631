export const columns = [
    {
      header: 'Id',
      prop: 'id'
    },
    {
      header: 'Date',
      prop: 'dt'
    },
    {
      header: 'Versement',
      prop: 'pm',
      type: 'number'
    },
    {
      header: 'Interest',
      prop: 'ip',
      type: 'number'
    },
    {
      header: 'Capital',
      prop: 'cp',
      type: 'number'
    },
    {
      header: 'Int. %',
      prop: 'rc',
      type: 'percent'
    },
    {
      header: 'Vers. Sup.',
      prop: 'lump',
      type: 'number'
    },
    {
      header: 'Solde',
      prop: 'solde',
      type: 'number'
    },
  ];