import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'levsh';
  param = { value: 'world' };
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private auth: AuthService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService) {
    translate.addLangs(['en', 'fr', 'zh']);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      
      rt.data.subscribe(data => {
        const locale = data.ogLocale.substring(0,2);
        this.document.documentElement.lang = locale; 
        this.translate.use(locale);
        this.titleService.setTitle(data.title)

        this.metaService.addTags([
          { name: 'og:description', content: data.ogDescription },
          { name: 'description', content: data.description },
          { name: 'keywords', content: data.keywords },
          { name: 'og:locale', content: data.ogLocale },
        ]);
      });
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
