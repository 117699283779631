import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject, Input, LOCALE_ID, Output
} from '@angular/core';
import { DecimalPipe, formatNumber } from '@angular/common';

@Component({
  selector: 'app-grid-scenario',
  templateUrl: './grid-scenario.component.html',
  styleUrls: ['./grid-scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridScenarioComponent implements AfterViewInit {
  @Input() columns: string[];
  @Input() rows: any[];

  /**
   * A cell or row was focused via keyboard or mouse click.
   */
  @Output() activate: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  clickRow(event: MouseEvent, row): void {
    this.activate.emit({
      type: 'click',
      event,
      row
    });
  }

  trackByFn(index, item) {
    return item.id;
  }

  formatCellValue(row, col): any {
    const cell = row[col.prop];
    if (col.type !== undefined) {
      switch (col.type) {
        case 'number':
          return formatNumber(cell, this.locale, '1.2-2');
          break;
        case 'dto':
          return cell.dateStr;
          break;
      }
    }

    return cell;
  }

  public getStyles(): any {
    // console.log('columns', this.columns.length, this.columns);
    return {
      display: 'grid',
      'align-items': 'center',
      'grid-template-rows': `36px 1fr`,
      'grid-template-columns': `repeat(${this.columns.length}, 1fr)`,
    };
  }

  public getStylesSpaned(): any {
    return {
      display: 'grid',
      'grid-column': `span 6`,
    };
  }


  public getBodyStyles(): any {
    return {
      display: 'grid',
      'grid-column': '1 / ' + (this.columns.length + 1),
      'grid-template-columns': `repeat(${this.columns.length}, 1fr)`,
    };
  }
}
