import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  exports: [
    FormsModule,
    OverlayModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule]
})
export class MaterialModule { }
