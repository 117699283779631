import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import * as ScenarioActions from './scenario.actions';
import * as AuthActions from '../auth/auth.actions';
import { getCurrentScenario, getScenarios } from './scenario.selectors';
import { HypoService } from '@app/services/hypo.service';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AddLoan, RemoveLoan, UpdateLoan } from '../loan';
import { ChangeScenariosSuccess, getCurrentLoan, SelectScenario } from '.';
import { SharedService } from '@app/services/shared.service';
import { AuthTokenService } from '@app/services/auth-token.service';

@Injectable()
export class ScenarioEffects {
    userAccountSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.UserAccountSuccess),
        map(action => action.user.scenarios),
        tap(x => console.log(x)),
        filter(_ => _ !== null),
        map(scenarios => ScenarioActions.LoadScenarios(scenarios))
    ));

    addLoan$ = createEffect(() => this.actions$.pipe(
        ofType(AddLoan),
        map(payload => payload.loan),
        // withLatestFrom(this.sharedService.scenario),
        map(loan => ScenarioActions.AddLoanToScenario(loan))
    ), { dispatch: true});

    updateLoan$ = createEffect(() => this.actions$.pipe(
        ofType(UpdateLoan),
        map(payload => payload.loan),
        withLatestFrom(this.sharedService.scenario),
        map(([_, scenario]) => ScenarioActions.UpdateLoanToScenario([ _, scenario]))
    ), { dispatch: true});

    removeLoan$ = createEffect(() => this.actions$.pipe(
        ofType(RemoveLoan),
        withLatestFrom(this.sharedService.scenario),
        map(([_, scenario]) => ScenarioActions.RemoveLoanFromScenario([ _, scenario]))
    ), { dispatch: true});

    select$ = createEffect(() => this.actions$.pipe(
        ofType(SelectScenario),
        map((payload) => this.sharedService.changeScenario(payload.scenario))
    ), { dispatch: false});

    reload$ = createEffect(() => this.actions$.pipe(
        ofType(ScenarioActions.ChangeScenariosSuccess),
        withLatestFrom(this.store.select(getCurrentLoan)),
        map(([_, loan]) => this.sharedService.changeLoan(loan))
    ), { dispatch: false});

    reloadScenario$ = createEffect(() => this.actions$.pipe(
        ofType(ScenarioActions.ChangeScenariosSuccess),
        withLatestFrom(this.store.select(getCurrentScenario)),
        map(([_, scenario]) => this.sharedService.changeScenario(scenario))
    ), { dispatch: false});

    changeScenario$ = createEffect(() => this.actions$.pipe(
        ofType(...[
            ScenarioActions.AddLoanToScenario,
            ScenarioActions.UpdateLoanToScenario,
            ScenarioActions.AddScenario,
            ScenarioActions.UpdateScenario,
            ScenarioActions.RemoveScenario,
            ScenarioActions.RemoveLoanFromScenario,
            ScenarioActions.AddElement,
            ScenarioActions.RemoveElement
        ]),
        map(loan => ScenarioActions.ChangeScenariosSuccess(loan))
    ));

    saveScenarios$ = createEffect(() => this.actions$.pipe(
        ofType(ChangeScenariosSuccess),
        filter(_ => this.authToken.token !== undefined),
        withLatestFrom(this.store.select(getScenarios)),
        concatMap(([_ , loans]) => this.hypoService.save(loans).pipe(
            map(user => ScenarioActions.SaveScenariosSuccess(user)),
            catchError(error => of(ScenarioActions.SaveScenariosFailure({ error })))
        ))
    ));

    constructor(
        private store: Store,
        private actions$: Actions,
        private authToken: AuthTokenService,
        private hypoService: HypoService,
        private sharedService: SharedService
    ) { }
}
