import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AppState } from './store';
import { Store } from '@ngrx/store';
import * as AuthActions from './store/auth';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare global {
  interface Window { LEVSH_APP_STATE: any; }
}

@Injectable()
export class AppEffects {

  constructor(private actions$: Actions, private store: Store<AppState>) {
    const appStateName = 'LEVSH_APP_STATE';

    this.store.dispatch(AuthActions.AppInit());

    this.store.select(state => state)
      .pipe(filter(_ => environment.debug))
      .subscribe(_ => {
        window.LEVSH_APP_STATE = _;
    });
  }


}

