import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IScenario } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as ScenarioActions from './scenario.actions';
import { v4 as uuidv4 } from 'uuid';
import { ElementType } from 'src/models/element-type.enum';
import { ILoan } from 'src/classes/loan';

export interface ScenarioState extends EntityState<IScenario> {
  selected: string;
  selectedLoan: ILoan;
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<IScenario> = createEntityAdapter<IScenario>({});

export const initialState = adapter.getInitialState({
  selected: null,
  selectedLoan: null,
  loading: false,
  error: null
});

export const reducer = createReducer(initialState,
  on(ScenarioActions.AddScenario, (state, action) => adapter.addOne(action.scenario, state)),
  on(ScenarioActions.UpdateScenario, (state, action) => adapter.updateOne(action.scenario, state)),
  on(ScenarioActions.RemoveScenario, (state, action) => adapter.removeOne(action.id, state)),
  on(ScenarioActions.LoadScenarios, (state, action) => adapter.addMany(action.scenarios, state)),
  on(ScenarioActions.AddLoanToScenario, (state, action) => addLoan(state, action)),
  on(ScenarioActions.UpdateLoanToScenario, (state, action) => updateLoan(state, action)),
  on(ScenarioActions.RemoveLoanFromScenario, (state, action) => removeElement(state, action)),
  on(ScenarioActions.SelectScenario, (state, action) => ({ ...state, selected: action.scenario.id })),
  on(ScenarioActions.SelectLoan, (state, action) => ({ ...state, selectedLoan: action.loanId })),
  on(ScenarioActions.AddElement, (state, action) => addElement(state, action)),
  on(ScenarioActions.RemoveElement, (state, action) => removeElement(state, action)),
);

/**
 * Add mortgage to scenario
 *
 * @param state
 * @param action
 */
 function addLoan(state, action) {
  let original = null;
  let mortgages = [];
  
  const entities = { ...state.entities };
  const item = action.payload;
  const loan = {
    ...item,
    type: ElementType.Mortgage
  }

  if (item['scenario'] !== '' && item['scenario'] !== null) {
    original = entities[item['scenario']];
    mortgages = original['elements'];
  } else {
    original = {
      id: uuidv4(),
      name: 'scenario ' + Object.keys(entities).length
    };
  }

  original = {
    ...original,
    elements: [...mortgages, loan]
  }

  return {
    ...adapter.upsertOne(original, state),
    selected: original.id,
    selectedLoan: item.id,
    padChanged: true
  };
}

/**
 * Update mortgage to scenario
 *
 * @param state
 * @param action
 */
 function updateLoan(state, action) {
  let original = null;
  let mortgages = [];
  const entities = { ...state.entities };
  const [ item, scenario ] = action.payload;

  if (scenario !== null && scenario['id'] !== null) {
    original = entities[scenario['id']];
    mortgages = [...original['elements']];
    const id = mortgages.findIndex(x => x.id === item.id);
    let mortgage = {
      ...mortgages[id]
    }
    
    if (Object.keys(item.changes).length === 1 && Object.keys(item.changes)[0] === 'transactions') {
      mortgage.transactions = [...item.changes.transactions];
    } else {
      mortgage = item.changes;
    }
    
    mortgages[id] = mortgage;
  }

  original = {
    ...original,
    elements: [...mortgages]
  }

  return {
    ...adapter.upsertOne(original, state),
    padChanged: true
  };
}


/**
 * Remove element from scenario
 *
 * @param state
 * @param action
 */
 function removeElement(state, action) {
  let original = null;
  let mortgages = [];
  const entities = { ...state.entities };
  const element = action.element;
  // console.log(element);

  if (element.scenario !== null) {
    original = entities[element.scenario];
    mortgages = [...original['elements']];
    const id = mortgages.findIndex(x => x.id === element.id);
    mortgages.splice(id, 1);
  }

  original = {
    ...original,
    elements: [...mortgages]
  }

  return {
    ...adapter.upsertOne(original, state),
    padChanged: true
  };
}

/**
 * Add an element to scenario
 *
 * @param state
 * @param action
 */
 function addElement(state, action) {
  let original = null;
  let elements = [];
  
  const entities = { ...state.entities };
  const payload = action.payload;
  const item = payload;
  const type = +payload.type;
  const element = {
    ...item,
    type
  }
  console.log(entities, item, action);

  if (item['scenario'] !== '' && item['scenario'] !== null) {
    original = entities[item['scenario']];
    elements = original['elements'];
  } else {
    original = {
      id: uuidv4(),
      name: 'scenario ' + Object.keys(entities).length
    };
  }

  original = {
    ...original,
    elements: [...elements, element]
  }

  return {
    ...adapter.upsertOne(original, state),
    selected: original.id,
    selectedLoan: item.id
  };
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
