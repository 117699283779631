import { IScenario } from '@models';
import { Update } from '@ngrx/entity';
import { createAction } from '@ngrx/store';
import { ILoan } from 'src/classes/loan';
import { IScenarioElement } from 'src/models/scenario-element.model';

export const AddScenario = createAction(
  '[Scenario] Add Scenario',
  (scenario: IScenario) => ({ scenario })
);

export const UpdateScenario = createAction(
  '[Scenario] Update Scenario',
  (scenario: Update<IScenario>) => ({ scenario })
);

export const RemoveScenario = createAction(
  '[Scenario] Remove Scenario',
  (id: string) => ({ id })
);

export const LoadScenarios = createAction(
  '[Scenario] Load Scenarios',
  (scenarios: IScenario[]) => ({ scenarios })
);

export const SelectScenario = createAction(
  '[Scenario] Select Scenario',
  (scenario: IScenario) => ({ scenario })
);

export const ChangeScenariosSuccess = createAction(
  '[Scenario] Change Scenarios Success',
  (payload: any) => ({ payload })
);

export const SaveScenarios = createAction(
  '[Scenario] Save Scenarios',
  (scenarios: IScenario[]) => ({ scenarios })
);

export const SaveScenariosSuccess = createAction(
  '[Scenario] Save Scenarios Success',
  (payload: any[]) => ({ payload })
);

export const SaveScenariosFailure = createAction(
  '[Scenario] Save Scenarios Failure',
  (error: any) => ({ error })
);

export const SelectLoan = createAction(
  '[Scenario] Select Loan',
  (loanId: string) => ({ loanId })
);

export const AddLoanToScenario = createAction(
  '[Scenario] Add Loan',
  (payload: any) => ({ payload })
);

export const UpdateLoanToScenario = createAction(
  '[Scenario] Update Scenario Loan',
  (payload: any[]) => ({ payload })
);

export const RemoveLoanFromScenario = createAction(
  '[Scenario] Remove Loan From Scenario',
  (payload: any[]) => ({ payload })
);

export const AddElement = createAction(
  '[Scenario] Add Element',
  (payload: any) => ({ payload })
);

export const RemoveElement = createAction(
  '[Scenario] Remove Element',
  (element: IScenarioElement) => ({ element })
);

export const ScenarioActionTypes: string[] = [
  AddScenario.type,
  RemoveScenario.type,
];
