import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutModule } from './layout/layout.module';
import { PopupComponent } from './popup/popup.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AddButtonComponent } from './add-button/add-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@app/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    LayoutModule,
    OverlayModule,
    TranslateModule.forChild()
  ],
  declarations: [
    PopupComponent,
    AddButtonComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    PopupComponent,
    AddButtonComponent,
  ]
})
export class SharedModule { }
