<div *ngIf="(elements$ | async)">
  <div class="panel">
    <div class="panel-hdr">
      <h2>{{ 'L_MYELEMENTS_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
      <div class="panel-content">

        <div class="bubble-list-container">
          <div *ngFor="let elem of (elements$ | async)" (click)="view(elem)">
            <element-item [data]="elem"></element-item>
          </div>

          @for (elem of elements$ | async; track elem.id) {
            <div (click)="view(elem)" class="case" [ngClass]="{'active': elementId === elem.id}">
              <a href="javascript:void(0)">{{ elem.name }}</a><br>
              <span>$ {{ elem.amount | number:'1.0-0' }}</span> &nbsp; {{ elem.type }} &nbsp;
              {{
              elem.startsAt }}
              <span>&nbsp; <a (click)="remove(elem)">remove</a></span>
            </div>
          }
        </div>

      </div>
    </div>
  </div>
</div>