import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { RemoveScenario, UpdateScenario } from '@app/store/scenario';

import { ILoan, Loan } from '../../../../classes/loan';
import { Scenario } from 'src/classes/scenario';
import { IScenario } from '@models';

@Component({
  selector: 'app-scenario-header',
  templateUrl: './scenario-header.component.html',
  styleUrls: ['./scenario-header.component.scss']
})
export class ScenarioHeaderComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  scenarioEx: Scenario;
  scenario: IScenario;
  isOpened = false;

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.scenarioEx.pipe(
      takeUntil(this.destroy$)
    ).subscribe((scenarioEx) => {
        this.scenarioEx = scenarioEx;
    });

    this.sharedService.scenario.pipe(
      takeUntil(this.destroy$)
    ).subscribe(scenario => this.scenario = scenario);
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  delete() {
    this.store.dispatch(RemoveScenario(this.scenarioEx.id));
  }

  updateScenarioTitle(event) {
    console.log('repsonse', event);
    const scenario = {
      ...this.scenario,
      name: event
    };
    this.store.dispatch(UpdateScenario({ id: this.scenario.id, changes: scenario }));
  }
}
