import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '@models';
import { Store } from '@ngrx/store';
import { NullToken } from '@app/store/auth/auth.actions';
import { AuthTokenService } from './auth-token.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = '/api/auth';
  constructor(
    private http: HttpClient,
    private store: Store,
    private authService: AuthTokenService,
    private jwtHelper: JwtHelperService) { }

  public getToken(): any {
    return this.authService.token;
  }

  login(obj: any): Observable<User> {
    return this.http.post<User>(this.url, { f: 'login', login: obj.username, up: obj.password, souvenir: 1 });
  }

  emailLogin(email: string, password: string): Observable<User> {
    return this.http.post<User>(this.url, { f: 'login', email, up: password });
  }

  googleLogin(email: string, name: string, googleId: string, token: string, gimg: string): Observable<User> {
    return this.http.post<User>(this.url, { f: 'glogin', name, email, auth: googleId, token, image: gimg });
  }

  facebookLogin(email: string, facebookId: string, name: string, fimg: string): Observable<User> {
    return this.http.post<User>(this.url, { f: 'fblogin', email, auth: facebookId, name, image: fimg });
  }

  public isAuthenticated(): boolean {
    const token = this.authService.token;
    if (this.jwtHelper.isTokenExpired(token)) {
      this.store.dispatch(NullToken());
      return true;
    }
    return false;
  }

  public logout(): void {
    this.authService.dumpToken = null;
  }

  account(id: string): Observable<User> {
    const params = new HttpParams().set('id', id);
    return this.http.get<User>(this.url, { params });
  }
}
