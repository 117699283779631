<header id="header">
    <div id="logo-group">
        <div id="logo" class="logo">
            <a class="navbar-brand" href="#"><span class="prefix">PEUH</span><span class="suffix">.com</span></a>
            <div class="small-title">{{ 'L_MAIN_TITLE' | translate }}</div>
        </div>
    </div>

    <div></div>

    <div class="ml-auto d-flex">

        <div class="nav-elem">
            <app-language></app-language>
        </div>
        <div class="nav-elem" *ngIf="!(user$ | async).id">
            <a class="header-icon" href="#modal-transparent" (click)="showLogin($event)">
                <i class="fal fa-power-off" aria-hidden="true"></i> &nbsp;Login
            </a>
        </div>
        <div class="nav-elem">
            <div id="logged_user">{{(user$ | async).fullname}}</div>
            <div *ngIf="(user$ | async).id">
                <a class="header-icon" href="#" (click)="logout($event)">D&eacute;connexion</a>
            </div>
        </div>
        <div class="nav-elem">
            <div id="logged_user"><img referrerpolicy="no-referrer" src="{{(user$ | async).image}}" class="user-image"></div>
        </div>

    </div>

</header>