import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Loan } from '../../classes/loan';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from '@app/services/shared.service';
import { IScenario } from '@models';
import { getLoggedIn } from '@app/store/auth';

@Component({
  selector: 'app-home',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
  loans: Loan[] = [];
  subscription: Subscription;
  scenario$: Observable<IScenario> = this.getScenario();
  loanFormShown$: Observable<boolean> = this.sharedService.loanFormShown;
  elementFormShown$: Observable<boolean> = this.sharedService.elementFormShown;
  loggedIn$: Observable<boolean> = this.store.select(getLoggedIn);

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private store: Store) { }

  getScenario(): Observable<IScenario> {
    return this.sharedService.scenario;
  }

}
