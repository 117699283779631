<div>
  <div class="panel">
    <div class="panel-hdr">
      <h2>{{ 'L_SCENARIO_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
      <div class="panel-content">

        <div #dyg>

          <!--
            Change for Recharts like ratehub graphs 
            https://www.npmjs.com/package/recharts
            At least give it a try
          -->
          <app-dygraphs [data]="data" [options]="options" >
          </app-dygraphs>
         
        </div>

      </div>
    </div>
  </div>
</div>

<div class="label-3d" #label3d>
  <h4>Revue du mois</h4>
  <div class="label-infos" #infos></div>
</div>
    
