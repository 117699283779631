<h1 mat-dialog-title>
    <h4 class="font-xl">
        <b>Transaction</b>
    </h4>
</h1>

<form [formGroup]="form" (ngSubmit)="submit()" class="yu-form">

    <div mat-dialog-content>
        <section>

            <div class="form-group">
                <label class="form-label">{{ 'L_ADJUST_VERS' | translate }}</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="icon-pre fal fa-dollar-sign"></i>
                        </span>
                    </div>
                    <input class="form-control" [formControl]="form.controls['vers']" type="text"
                        data-lpignore="true">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">{{ 'L_ADJUST_RATE' | translate }}</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="icon-pre fal fa-percent"></i>
                        </span>
                    </div>
                    <input class="form-control" [formControl]="form.controls['interet']" type="text"
                        data-lpignore="true">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">{{ 'L_ADJUST_EXTRAVERS' | translate }}</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="icon-pre fal fa-dollar-sign"></i>
                        </span>
                    </div>
                    <input class="form-control" [formControl]="form.controls['verssup']" type="text"
                        data-lpignore="true" placeholder="Ex.: 2500">

                </div>
            </div>


        </section>

        <section>

            <div class="form-group">
                <div class="lab_radio">
                    <input type="radio" value="1" id="rad_change" name="rad_change"
                        style="text-align: right; padding-right: 2px" checked />
                    M&ecirc;me date d'&eacute;ch&eacute;ance
                    &nbsp; &nbsp; <input type="radio" value="2" id="rad_change" name="rad_change"
                        style="text-align: right; padding-right: 2px" />
                    M&ecirc;me versement
                </div>
            </div>

        </section>

        <div>

        </div>

    </div>
    <div mat-dialog-actions>
        <div class="btn-group">
            <button type="button" mat-dialog-close class="btn btn-block btn-default">{{ 'L_ACTION_CANCEL' | translate
                }}</button>
            <button type="submit" class="btn btn-block btn-success">Ok</button>
        </div>
    </div>
</form>