import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { MaterialModule } from '@app/material.module';
import { XEditableComponent } from './x-editable.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OverlayModule,
  ],
  declarations: [
    XEditableComponent,
  ],
  exports: [
    XEditableComponent
  ]
})
export class XEditableModule { }
