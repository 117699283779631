<h1 mat-dialog-title>
    <h4 class="font-xl">
        <b>Log in</b>
    </h4>
</h1>

<div mat-dialog-content>

    <form [formGroup]="loginForm" (ngSubmit)="submit(loginForm.value)" class="yu-form">

        <section>

            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="icon-pre fal fa-at"></i>
                        </span>
                    </div>
                    <input type="email" class="form-control" [formControl]="loginForm.controls['email']"
                        placeholder="Email address">
                </div>
            </div>

        </section>

        <div>

            <div class="form-group">

                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-pre fal fa-key"></i></span>
                    </div>
                    <input type="password" class="form-control" [formControl]="loginForm.controls['password']"
                            placeholder="Password">
                    
                </div>

            </div>

        </div>

        <div>

            <div class="form-group">
                <button type="submit" class="btn btn-block btn-green">Log in</button>
            </div>

        </div>

        <div>
            <div class="font-sm">
                <div class="help-block">
                    <a href="">Forget the password ?</a>
                </div>
            </div>
        </div>

        <div class="font-xs">
            Don't have an account yet?
            <a ng-click="registerUser()" href="javascript:void(0);">Sign Up</a>
        </div>

    </form>

    <div>

        <h5 class="lined"> or </h5>
        <div class="social-buttons">
            <button type="button" class="btn btn-facebook" (click)="facebookLogin()">
                <i class="fab fa-facebook"></i> &nbsp;
                <b>Login</b> with
                <b>Facebook</b>
            </button>
            <br>
            <button type="button" class="btn btn-google" id="googleBtn">
                <i class="fab fa-google"></i> &nbsp;
                <b>Sign</b> in with
                <b>Google</b>
            </button>
        </div>

    </div>
</div>