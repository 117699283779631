import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { fromEvent, tap, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
    selector: 'app-x-editable',
    templateUrl: './x-editable.component.html',
    styleUrls: ['./x-editable.component.scss'],
    styles: [`
    :host {
        text-decoration: none;
        color: #428bca;
        border-bottom: dashed 1px #428bca;
        cursor: pointer;
    }`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class XEditableComponent {
    @Input() trigger: CdkOverlayOrigin;
    @Output() hide = new EventEmitter<any>();
    @Output() pop = new EventEmitter<any>();
    @Output() response = new EventEmitter<string>();
    @Input() title: string;

    isOpened = false;
    val: string;
    _classMap = {};
    positionPairs: ConnectionPositionPair[] = [
        {
            offsetX: -30,
            offsetY: 0,
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
        },
    ];

    constructor(
        private elRef: ElementRef,
        private cd: ChangeDetectorRef) {

        const open$ = fromEvent(this.elRef.nativeElement, 'click').pipe(
            map((point: MouseEvent) => point.target),
            tap((x: HTMLElement) => this.val = x.outerText));

        open$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.position();
            this.changeState();
        });


    }

    /**
     * Some tricks to position the dialog ... found no one perfect
     * 
     * 1. [cdkConnectedOverlayPositions]="positionPairs"
     *      Putting it in the ng-template properties, problem is it blurs the controls because of the translateX command
     * 2. setTimeout
     *      There a quick delay so the dialog moves right after appearing so it's not nice  
     */
    position() {
        this._classMap = {
            'trans': true
        };
        this.cd.markForCheck();
    }

    cancel(event) {
        event.preventDefault();
        this.isOpened = false;
        this.hide.emit();
        this.cd.markForCheck();
    }

    save(event) {
        event.preventDefault();
        this.response.emit(this.val);
        this.changeState();
    }

    connectedOverlayDetach() {
        if (this.isOpened) {
            this.changeState();
        }
    }

    private changeState() {
        this.isOpened = !this.isOpened;
        this.isOpened ? this.pop.emit() : this.hide.emit();
        this.cd.markForCheck();
    }

    private isMovedOutside(overlayOriginEl, dialog, event): boolean {
        return !(overlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']));
    }
}
