import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as LoanActions from './loan.actions';
import { ILoan } from 'src/classes/loan';

export interface LoanState extends EntityState<ILoan> {
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<ILoan> = createEntityAdapter<ILoan>({});

export const initialState = adapter.getInitialState({
  loading: false,
  error: null
});

export const reducer = createReducer(initialState,
  on(LoanActions.SetCurrentLoan, (state, action) => {
    return ({
      ...state,
      current: action.loan
    })
  }),
  on(LoanActions.AddLoan, (state, action) => adapter.addOne(action.loan, state)),
  on(LoanActions.UpdateLoan, (state, action) => adapter.updateOne(action.loan, state)),
  on(LoanActions.RemoveLoan, (state, action) => adapter.removeOne(action.id, state)),
  on(LoanActions.LoadLoans, (state, action) => adapter.addMany(action.loans, state))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
