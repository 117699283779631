<content>

    <div class="item-left">

        <article class="col-sm-12">
            <app-add-button></app-add-button>
            <app-loan-form *ngIf="(loanFormShown$ | async)"></app-loan-form>
            <app-element-form *ngIf="(elementFormShown$ | async)"></app-element-form>
        </article>
        <article class="col-sm-12" *ngIf="loggedIn$ | async">
            <app-scenario-list></app-scenario-list>
        </article>
        <article class="col-sm-12" *ngIf="loggedIn$ | async">
            <app-loan-list></app-loan-list>
        </article>
        <article class="col-sm-12" *ngIf="loggedIn$ | async">
            <app-element-list></app-element-list>
        </article>
        <div>
            <app-dev-frame></app-dev-frame>
        </div>

    </div>
    <div class="item-center">
        <app-scenario-header *ngIf="(scenario$ | async)"></app-scenario-header>
        <app-detail-frame></app-detail-frame>
        <!-- <app-scenario-graph></app-scenario-graph> -->
    </div>
    <!-- THIRD COLUMN -->

    <div class="item-right">
        <app-loan-stats></app-loan-stats>
        <app-loan-updates></app-loan-updates>
    </div>

</content>