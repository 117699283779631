import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, formatNumber } from '@angular/common';

@Pipe({
  name: 'rowtype'
})

export class RowTypePipe implements PipeTransform {
  transform(value, args?): any {
    const searchText = new RegExp(args, 'ig');
    if (value) {
      console.log('pipe', value, args);
      var formattedNumber= 
      formatNumber(value, '1.2-2');
      return value;
      /*
      return value.filter(city => {
        if (city.ville || city.etat || city.pays) {
          return city.ville.search(searchText) !== -1 ||
          city.etat.search(searchText) !== -1 ||
          city.pays.search(searchText) !== -1;
        }
      }) as any;
      */
    }
  }
}
