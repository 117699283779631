import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as fromStore from './store';
import { AppEffects } from './app.effects';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './+home/home.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './+dashboard/dashboard.component';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthTokenFactory, AuthTokenService } from './services/auth-token.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { RoutingService } from './services/routing.service';
import { ScenarioGraphComponent } from './scenario-graph/scenario-graph.component';
import { DygraphsModule } from './shared/graphs/dygraphs/dygraphs.module';
import { ScenarioListComponent } from './scenario-list/scenario-list.component';
import { ElementListComponent } from './element-list/element-list.component';
import { LoanListComponent } from './loan-list/loan-list.component';

export function tokenGetter(): string | null {
  return localStorage.getItem('id_token');
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const storeDevToolsImport = [
  !environment.production ? StoreDevtoolsModule.instrument() : []
];

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        DashboardComponent,
        LoanListComponent,
        ElementListComponent,
        ScenarioListComponent,
        ScenarioGraphComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        CommonModule,
        SharedModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        OverlayModule,
        DygraphsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: ['example.com'],
                disallowedRoutes: ['http://example.com/examplebadroute/'],
            },
        }),
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // HotkeysModule.forRoot(),
        StoreModule.forRoot(fromStore.reducers, {
            metaReducers: fromStore.metaReducers
        }),
        storeDevToolsImport,
        EffectsModule.forRoot([...fromStore.effects, AppEffects])], providers: [
        RoutingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AuthTokenFactory,
            deps: [AuthTokenService],
            multi: true
        },
        // { provide: MAT_DATE_LOCALE, useValue: 'zh' },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
