import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { IScenario, User } from '@models';
import { ILoan, Loan } from '../../classes/loan';

@Injectable({
  providedIn: 'root'
})
export class HypoService {

  private url = '/api/auth';

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService) { }

  getLoans(): Observable<Loan[]> {
    return this.http.post<Loan[]>(this.url, { ajax: '1', f: 'getloans' });
  }

  save(scenarios: IScenario[]): Observable<IScenario[]> {
    return this.http.post<IScenario[]>(this.url, scenarios);
  }

}

