<div *ngIf="loanEx">
    
    <div class="primary">
        <ul>
            <li>
                <a href="javascript:void(0)" *ngIf="!loanEx" (click)="dlgAdjust(loanEx)" class="editable editable-click editable-empty">Unsaved mortgage</a>
                
                <div *ngIf="loanEx.name !== undefined">
                    <app-x-editable (response)="updateLoanName($event)" (hide)="isOpened = false"
                        (pop)="isOpened = true" #trigger="cdkOverlayOrigin" [trigger]="trigger"
                        cdkOverlayOrigin>{{ loanEx.name }}</app-x-editable>
                </div>
            </li>
            <li>
                <span class="primary-label">AMOUNT</span>
                <div class="amount">$ {{ loanEx.hypotheque | number:0 }}</div>
            </li>
            <li>
                <span class="primary-label">% DONE</span>
                <div><span class="">{{ ((loanEx.hypotheque - loanEx.stats.leftToPay) / loanEx.hypotheque) * 100 | number:0 }}</span></div>
            </li>
            <li>
                
            </li>
        </ul>
    </div>
    
    <div class="secondary">
        <ul>
            <li>
                <span class="sec-label">Rate</span>
                <div>{{ loanEx.rate }}%</div>
            </li>
            <li>
                <span class="sec-label">Amort</span>
                <div>{{ loanEx.years }}</div>
            </li>
            <li>
                <span class="sec-label">Frequency</span>
                <div>{{ loanEx.freq }}</div>
            </li>
            <li>
                <span class="sec-label">Started on</span>
                <div>{{ loanEx.dateDebut }}</div>
            </li>
            <li>
                <div class='btn-group' *ngIf="loanEx.id">
                    
                    <button class="btn btn-default btn-sm" (click)="delete(loanEx)">
                        <i class="fal fa-trash" aria-hidden="true"></i>
                    </button>
                </div>
            </li>
        </ul>
    </div>

</div>