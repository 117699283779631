import * as fromScenario from './scenario.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IScenario } from '@models';
import { ElementType } from 'src/models/element-type.enum';

export const getScenarioState = createFeatureSelector<fromScenario.ScenarioState>('scenario');

export const getColumnLoading = createSelector(getScenarioState,
  (state: fromScenario.ScenarioState) => state.loading);

export const getScenarios = createSelector(getScenarioState, fromScenario.selectAll);
export const getCurrentScenarioId = createSelector(getScenarioState, (state: fromScenario.ScenarioState) => state.selected);
export const getCurrentLoan = createSelector(getScenarioState, (state: fromScenario.ScenarioState) => state.selectedLoan);

export const getCurrentScenario = createSelector(getScenarios, getCurrentScenarioId,
  (scenarios: IScenario[], selectedId) => {
    if (scenarios.length > 0 && selectedId) {
      const mi = scenarios.find((scenario: IScenario) => scenario.id === selectedId);
      // console.log('selector', selectedId, mi, scenarios);
      return Object.assign({}, mi as IScenario);
    } else {
      return null;
    }
  }
);

export const getLoans = createSelector(getCurrentScenario, (scenario: IScenario) => {
  if (scenario && scenario.elements) {
    return scenario.elements.filter(elem => elem.type === ElementType.Mortgage);
  } else {
    return null;
  }
});

export const getElements = createSelector(getCurrentScenario, (scenario: IScenario) => {
  if (scenario && scenario.elements) {
    return scenario.elements.filter(elem => elem.type !== ElementType.Mortgage);
  } else {
    return null;
  }
});


export const getCurrentLoanId = createSelector(getCurrentScenario, getCurrentLoan, (scenario: IScenario, loanId) => {
  if (scenario && loanId) {
    const id = scenario.elements.findIndex(x => x.id === loanId);
    if (id > -1) {
      return scenario.elements[id].id;
    }  
  }
  return null;
});

