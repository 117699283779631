import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { IScenario } from '@models';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ILoan } from 'src/classes/loan';

@Component({
  selector: 'app-detail-frame',
  templateUrl: './detail-frame.component.html',
  styleUrls: ['detail-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailFrameComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  scenario$: Observable<IScenario> = this.getScenario();
  loan$: Observable<ILoan> = this.getLoan();
  onScenario: boolean;

  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    const t = 1;
    this.sharedService.onScenario.pipe(
      takeUntil(this.destroy$)
    ).subscribe(x => {
      this.onScenario = x;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  toggle(isOn: boolean): void {
    this.sharedService.setOnScenario(isOn);
  }

  loanOrScenario() {
    return true;
  }

  getScenario(): Observable<IScenario> {
    return this.sharedService.scenario;
  }

  getLoan(): Observable<ILoan> {
    return this.sharedService.loan;
  }

  scenarioVisible() {
    return this.onScenario ? 'block' : 'none';
  }

  loanVisible() {
    return this.onScenario ? 'none' : 'block';
  }

}
