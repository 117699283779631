<div class="panel">

    <div class="panel-hdr">
        <h2>{{ 'L_INFOBOX_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
        <div class="panel-content">

            <form [formGroup]="loanForm" (ngSubmit)="submit(loanForm.value)" class="smart-form">

                <div class="form-group">
                    <label class="form-label">{{ 'L_INFOBOX_SCENARIO_FIELD' | translate }}</label>
                    <div class="select">
                        <select class="form-control" [formControl]="loanForm.controls['scenario']">
                            <option value="">{{ 'L_INFOBOX_NEWSCEN' | translate }}</option>
                            <option *ngFor="let scneario of (scenarios$ | async)" [value]="scneario.id">
                                {{scneario.name}}</option>
                        </select>
                        <i></i>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">{{ 'L_INFOBOX_AMOUNT' | translate }}</label>

                    <input class="form-control" [formControl]="loanForm.controls['hypotheque']" type="text"
                        data-lpignore="true">

                </div>

                <div class="form-group">
                    <label class="form-label">{{ 'L_INFOBOX_RATE' | translate }}</label>
                    <div class="input-group">

                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icon-pre fal fa-percent"></i>
                            </span>
                        </div>
                        <input class="form-control" [formControl]="loanForm.controls['rate']" type="text"
                            data-lpignore="true">
                    </div>
                </div>

                <div class="form-group">
                    <a href="javascript:void(0)" (click)="fset1 = !fset1" *ngIf="!fset1">{{ 'L_INFOBOX_MOREOPTS' |
                        translate }} [+]</a>
                    <a href="javascript:void(0)" (click)="fset1 = !fset1" *ngIf="fset1">{{ 'L_INFOBOX_LESSOPTS' |
                        translate }} [+]</a>
                </div>

                <div class="more" *ngIf="fset1">

                    <div class="form-group">
                        <label class="form-label">{{ 'L_INFOBOX_FREQ' | translate }}</label>
                        <div class="select">
                            <select class="form-control" [formControl]="loanForm.controls['freq']">
                                <option value="m">{{ 'L_INFOBOX_MONTHLY' | translate }}</option>
                                <option value="b">{{ 'L_INFOBOX_BIMONTH' | translate }}</option>
                                <option value="q">{{ 'L_INFOBOX_SEMWEEK' | translate }}</option>
                                <option value="w" selected>{{ 'L_INFOBOX_WEEKLY' | translate }}</option>
                            </select>
                            <i></i>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label">{{ 'L_INFOBOX_BEGINDATE' | translate }}</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="icon-pre fal fa-calendar"></i>
                                </span>
                            </div>
                            <input class="form-control" [formControl]="loanForm.controls['startsAt']" type="text"
                                is-open="debutOpened" uib-datepicker-popup class="form-control" data-lpignore="true"
                                placeholder="yyyy-MM-dd">
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label">{{ 'L_INFOBOX_AMORT' | translate }}</label>
                        <input class="form-control" [formControl]="loanForm.controls['years']" type="text"
                            data-lpignore="true" placeholder="Number of years">
                    </div>

                </div>

                <div class="form-group">
                    <a href="javascript:void(0)" (click)="fset2 = !fset2" *ngIf="!fset2">{{ 'L_INFOBOX_SHOWFANT' |
                        translate }} [+]</a>
                    <a href="javascript:void(0)" (click)="fset2 = !fset2" *ngIf="fset2">{{ 'L_INFOBOX_HIDEFANT' |
                        translate }} [+]</a>
                </div>

                <div class="more" *ngIf="fset2">

                    <section>
                        <label class="label">{{ 'L_INFOBOX_INSURANCE' | translate }}</label>
                        <label class="input">
                            <i class="icon-prepend fal fa-calendar"></i>
                            <input ng-model="newloan.assurance" type="text" is-open="debutOpened" uib-datepicker-popup
                                class="form-control" placeholder="yyyy-MM-dd">
                        </label>
                    </section>

                    <section>
                        <label class="label">{{ 'L_INFOBOX_RAP' | translate }}</label>
                        <label class="input">
                            <input ng-model="newloan.rap" type="text" placeholder="Number of years">
                        </label>
                    </section>

                </div>

                <section>

                    <div class="btn-group">
                        <button type="submit" class="btn btn-sm btn-default">Calculer</button>
                    </div>

                </section>


            </form>



        </div>
    </div>
</div>