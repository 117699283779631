
import { Update } from '@ngrx/entity';
import { createAction } from '@ngrx/store';
import { ILoan } from 'src/classes/loan';

export const SetCurrentLoan = createAction(
  '[Loan] Set Current Loan',
  (loan: ILoan) => ({ loan })
);

export const AddLoan = createAction(
  '[Loan] Add Loan',
  (loan: ILoan) => ({ loan })
);

export const UpdateLoan = createAction(
  '[Loan] Update Loan',
  (loan: Update<ILoan>) => ({ loan })
);

export const RemoveLoan = createAction(
  '[Loan] Remove Loan',
  (id: string) => ({ id })
);

export const LoadLoans = createAction(
  '[Loan] Load Loans',
  (loans: ILoan[]) => ({ loans })
);

export const SaveLoans = createAction(
  '[Loan] Save Loans',
  (loans: ILoan[]) => ({ loans })
);

export const SaveLoansSuccess = createAction(
  '[Loan] Save Loans Success',
  (payload: any[]) => ({ payload })
);

export const SaveLoansFailure = createAction(
  '[Loan] Save Loans Failure',
  (error: any) => ({ error })
);

export const LoanActionTypes: string[] = [
  AddLoan.type,
  RemoveLoan.type,
];
