import { ElementType } from 'src/models/element-type.enum';
import { IScenarioElement } from 'src/models/scenario-element.model';

type DateStrMk = {
    dateStr: string;
    dateMk: number;
};

type YearsPeriods = {
    years: number;
    periods: number;
};

/**
 * The divisor related to the frequency
 */
const freqDiv: { [key: string]: number } = {
    w: 52,
    q: 26,
    b: 24,
    m: 12
};

/**
 * The number of days related to the frequency
 */
const freqDays: { [key: string]: number } = {
    w: 7,
    q: 14,
    b: 15.2,
    m: 30.4
};

export class ScenarioElement {
    id?: string;
    name: string;
    amount: number;
    freq: string = 'm';
    startsAt?: string = '2007-05-01';

    freqAmount: number = 1;
    accountType: ElementType;

    transactionCount = 600;

    /**
     * Calculated string representation of the start date of the loan ex.: 2021-02-24
     */
    dateDebut?: string;

    /**
     * Calculated number representing the starting date of the loan in second (EPOC Style I think)
     */
    private dateDebutMk?: number;

    /**
     *
     */
    rows?: any[];

    private amortizedAmount: number;

    constructor(element: IScenarioElement) {
        // console.log(' === ELEMENT === ', element, this.freq);
        this.id = element.id || null;
        this.name = element.name;
        this.amount = +element.amount;
        this.accountType = element.type;
        this.freqAmount = +element.freqAmount;
        this.freq = element.freqType;
        if (element.freqType === null || element.freqAmount === null) {
            this.transactionCount = 1;
        }
        if (element.amort) {
            this.amount = this.parseAmortization(this.amount);
        }
        
        this.setStartDate(element.startsAt);
    }

    public setId = (loanid: string): void => {
        this.id = loanid;
    }

    setName(loanname: string): void {
        this.name = loanname;
    }

    parseAmortization(fullAmount): number {
        const scenarioFreq = 26;
        const amount = fullAmount / scenarioFreq;
        this.freq = 'q';
        return amount;
    }


    build(): void {
        let trNo = 1;
        let prevDto = 0;

        let transactions = [];
        let balance = 0;
        this.rows = [];

        for (let t = 0; t <= (this.transactionCount - 1); t++) {
            const dto = this.getDateFromTransac(t) as DateStrMk;
            const dt = dto.dateStr;
            const mk = new Date(dto.dateMk);

            const row = {
                id: trNo++,
                mk: dto.dateMk,
                dt: dto.dateStr,
                amount: this.amount,
            };

            this.rows.push(row);
            prevDto = dto.dateMk;
        }
        // console.log(this.rows);
        // console.log(this.rows);
    }

    /**
     * Retourne une date calculée selon le numéro de transaction
     *
     * @param int trNo Numéro de transaction
     * @return date Date en format Y-m-d + format epoc
     */
    getDateFromTransac(trNo: number): DateStrMk {
        let mkCurrent = 0;
        let yr = 0;
        // let adjust_div_t = 0;
        let adjustDay;
        let dateObj;

        const datei = this.dateDebutMk;
        dateObj = new Date(this.dateDebut);

        yr = dateObj.getFullYear();
        // console.log(this.freq, this.freqAmount);
        switch (this.freq) {
            case 'd':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * this.freqAmount));
                break;
            case 'w':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * (7 * this.freqAmount)));
                break;
            case 'q':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * (14 * this.freqAmount)));
                break;
            case 'm':
                mkCurrent = dateObj.setMonth(dateObj.getMonth() + (trNo * this.freqAmount));
                break;
            case 'y':
                mkCurrent = dateObj.setFullYear(dateObj.getFullYear() + (trNo * this.freqAmount));
                break;
            case 'b':
                // ToDO: Fix it, the first row is wrong
                if (dateObj.getDate() > 15) {
                    // adjust_div_t = 2;
                    adjustDay = 1;
                } else {
                    // adjust_div_t = 0;
                    adjustDay = dateObj.getDate();
                }

                const dd = new Date(dateObj.setMonth(dateObj.getMonth() + ((trNo - 1) / 2)));
                mkCurrent = dd.setDate(dd.getDate() + ((trNo % 2) === 0 ? 15 : 0));
                break;
            default:
                mkCurrent = dateObj.setDate(dateObj.getDate());
        }

        const resultDate = new Date(mkCurrent);
        return { dateStr: resultDate.toISOString().slice(0, 10), dateMk: mkCurrent };
    }

    /**
     * Determine la date de depart du scenario
     * 
     * Search for the oldest date scanning through the elements
     *
     * @param dt The starting date of the loan
     */
    setStartDate(startsAt: any): void {
        const date = (startsAt !== '' && startsAt !== undefined) ? new Date(startsAt) : new Date();
        this.dateDebut = date.toISOString().slice(0, 10);
        this.dateDebutMk = Math.floor(date.getTime() / 1000);
    }
}

function inArrayObj(nameKey: number, prevDto: number, elements: any[]): any {
    let balance = 0;
    if (elements !== undefined && elements.length > 0) {
        for (const elem of elements) {
            balance += amountFrom(nameKey, prevDto, elem.rows);
        }
    }
    return balance;
}

function amountFrom(nameKey: number, prevDto: number, myArray) {
    let balance = 0;
    if (myArray !== undefined) {
        for (const row of myArray) {
            if (row.mk > prevDto && row.mk <= nameKey) {
                balance += row.pm;
            }
            if (row.mk > nameKey) {
                return balance;
            }
        }
    }
    return 0;
}

function inArray(nameKey: any, myArray: any[]): any {
    if (myArray === undefined) {
        return false;
    }

    for (const o of myArray) {
        if (o === nameKey) {
            return myArray[o];
        }
    }
    return false;
}

