import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { IScenario, User } from '@models';
import { ILoan, Loan } from '../../classes/loan';
import { AuthTokenService } from './auth-token.service';
import { AuthSuccess } from '@app/store/auth';
import { Store } from '@ngrx/store';
import { Scenario } from 'src/classes/scenario';
import { SelectLoan } from '@app/store/scenario';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _user = new BehaviorSubject<User | null>(null);
  private _loan = new BehaviorSubject<ILoan | null>(null);
  private _loanEx = new BehaviorSubject<Loan | null>(null);
  private _scenario = new BehaviorSubject<IScenario | null>(null);
  private _scenarioEx = new BehaviorSubject<Scenario | null>(null);
  private _onScenario = new BehaviorSubject<boolean | null>(null);
  private _loanFormShown = new BehaviorSubject<boolean | null>(null); 
  private _elementFormShown = new BehaviorSubject<boolean | null>(null);

  freq = [
    { key: 'm', name: 'Monthly', translate: 'L_INFOBOX_MONTHLY' },
    { key: 'b', name: 'Bi-Monthly', translate: 'L_INFOBOX_BIMONTH' },
    { key: 'q', name: 'Semi-Weekly', translate: 'L_INFOBOX_SEMWEEK' },
    { key: 'w', name: 'Weekly', translate: 'L_INFOBOX_WEEKLY' }
  ];

  user = this._user.asObservable();
  loan = this._loan.asObservable();
  loanEx = this._loanEx.asObservable();
  scenario = this._scenario.asObservable();
  scenarioEx = this._scenarioEx.asObservable();
  onScenario = this._onScenario.asObservable();
  elementFormShown = this._elementFormShown.asObservable();
  loanFormShown = this._loanFormShown.asObservable();

  constructor(
    private store: Store,
    private tokenService: AuthTokenService,
    private translate: TranslateService
    ) { }

  setOnScenario(isOn: boolean): void {
    this._onScenario.next(isOn);
  }
  

  changeUser(user: User): void {
    this.tokenService.token = user.token;
    this.store.dispatch(AuthSuccess(user));
    this._user.next(user);
  }

  changeLoan(data: ILoan): void {
    const loanEx = new Loan(data);
    loanEx.build();
    this.store.dispatch(SelectLoan(data.id));
    this._loan.next(data);
    this._loanEx.next(loanEx);
    this._onScenario.next(false);
  }

  changeScenario(data: IScenario): void {
    const scenarioEx = new Scenario(data);
    scenarioEx.build();
    this._scenario.next(data);
    this._scenarioEx.next(scenarioEx);
    this._onScenario.next(true);
  }

  trFreq(frequence: string): string {
    for (const freq of this.freq) {
      if (freq.key === frequence) {
        return this.translate.instant(freq.translate);
      }
    }
    return '';
  }

  openLoanForm(): void {
    this._elementFormShown.next(false);
    this._loanFormShown.next(true);
  }

  openElementForm(): void {
    this._loanFormShown.next(false);
    this._elementFormShown.next(true);
  }

  closeForms(): void {
    this._loanFormShown.next(false);
    this._elementFormShown.next(false);
  }

}
