<div [ngStyle]="getStyles()">
    <div class="cell grid-hdr grid__th" *ngFor="let column of columns">{{ column.header }}</div>
    <!-- The rest of the items in the list are the actual data -->
    <div [ngStyle]="getStylesSpaned()">
        <cdk-virtual-scroll-viewport itemSize="20" class="viewport grid-body">
            <div *cdkVirtualFor="let row of rows; let irow = index; trackBy: trackByFn" [ngStyle]="getBodyStyles()">
                <div [ngClass]="{'odd': irow % 2, 'cell': true, 'grid-row': true, 'ra': col.type === 'number'}"
                    (click)="clickRow($event, row)" *ngFor="let col of columns; let i = index; trackBy: trackByFn">
                    <!-- Visually ok, try to find a better (effective) way for these divs ifs - 2021-04-19 -->
                    <div class="change payment-changed" *ngIf="col.prop === 'pm' && row.pc">{{ formatCellValue(row, col)
                        }}</div>
                    <div class="change lump-payment" *ngIf="col.prop === 'lump' && row.lump">{{ formatCellValue(row,
                        col) }}</div>
                    <div class="change rate-changed" *ngIf="col.prop === 'rc' && row.rc">{{ formatCellValue(row, col) }}
                    </div>
                    <div
                        *ngIf="!((col.prop === 'pm' && row.pc) || (col.prop === 'rc' && row.rc) || (col.prop === 'lump' && row.lump))">
                        {{ formatCellValue(row, col) }}</div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
