import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { TokenRestore, AuthInit, LoggedOnce, LogoutAction } from '../store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.reducer';
import { StorageService } from './storage.service';

const ROLE_ADMIN = 1;

const USER_TOKEN = 'id_token';
const USER_LOGGED_ONCE = 'logged_once';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  public token$ = new BehaviorSubject(null);
  timeLeft: number;
  interval;

  constructor(
    private storage: StorageService,
    private store: Store<AuthState>
  ) { }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(AuthInit());

      this.storage.get(USER_TOKEN).then((token: any) => {

        if (environment.log.auth) {
          console.log((!!token ? 'logged' : 'not logged') + ' at boot');
        }

        if (!!token) {
          try {
            const payload = this.readPayload(token);
            this.autoLogout(payload);
            this.store.dispatch(TokenRestore(payload));
          } catch (error) {
            if (environment.log.auth) {
              console.log('!!token', error);
            }
            token = null;
          }
        }

        this.token = token;

        this.token$.pipe(
          switchMap(this.dumpToken),
          switchMap(this.updateLoggedOnce)).subscribe(() => { });
        resolve(token);
      },
        (error: any) => {
          resolve(null);
        }
      );
    });
  }

  dumpToken = (token: any) => {
    if (environment.log.auth) {
      console.log('\n\n\n================\ndump auth token', token);
    }

    if (!!token) {
      this.autoLogout(this.readPayload(token));
      return this.storage.set(USER_TOKEN, token);
    } else {
      return this.storage.remove(USER_TOKEN).then(() => null);
    }
  }

  updateLoggedOnce = (token: any) => {
    return this.storage.get(USER_LOGGED_ONCE).then((loggedOnce: any) => {
      if (token || loggedOnce) {
        this.store.dispatch(LoggedOnce({ payload: true }));
        return loggedOnce
          ? token
          : this.storage.set(USER_LOGGED_ONCE, Date.now()).then((_: any) => token);
      } else {
        return Promise.resolve(token);
      }
    });
  }

  set token(value) {
    this.token$.next(value);
  }

  get token(): any {
    return this.token$.value;
  }

  autoLogout(token): void {
    if (token) {
      const date = new Date(0);
      date.setUTCSeconds(token.exp);
      this.timeLeft = (date.valueOf() - new Date().valueOf()) / 1000;
      console.log('Set Auto Disconnect', date);
      this.startTimer();
    }
  }

  startTimer(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.store.dispatch(LogoutAction());
        clearInterval(this.interval);
      }
    }, 1000);
  }

  hasRole(role: string): boolean {
    const roles = this.getRoles();
    return roles ? roles.indexOf(role) > -1 : false;
  }

  getRoles(): Array<string> {
    const payload = this.readPayload(this.token);
    if (payload) {
      const roleStr = payload.auth;
      const roles = this.parseRoles(roleStr);
      return roles ? roles : [];
    }

    return [];
  }

  private parseRoles(roleStr: any): Array<string> {
    return roleStr ? roleStr.split(',') : null;
  }

  readPayload(token: any): any {
    const payload = this.getTokenPayload(token);
    return payload; // && payload.user ? Object.assign({roles: [], id: null},
    // {id: payload.user.id, roles: JSON.parse(payload.user.roles)}) : null
  }

  getTokenPayload(token: any): string {
    return token
      ? JSON.parse(this.b64DecodeUnicode(token.split('.')[1]))
      : null;
  }

  b64DecodeUnicode(str: string): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
}

export function AuthTokenFactory(service: AuthTokenService): Function {
  return () => service.load();
}
