<div *ngIf="(scenarios$ | async)">
  <div class="panel">
    <div class="panel-hdr">
      <h2>{{ 'L_MYSCENARIOS_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
      <div class="panel-content">

        @for (scenario of scenarios$ | async; track scenario.id) {
          <div (click)="view(scenario)" class="case" [ngClass]="{'active': scenarioId === scenario.id}">
            <a class="name" href="javascript:void(0)">{{scenario.name}}</a><br>
            <span>$ {{scenario.hypotheque | number:'1.0-0'}}</span> &nbsp; {{scenario.rate |
            number:'1.1-2'}}% &nbsp; {{scenario.freq}}
          </div>
        }

      </div>
    </div>
  </div>
</div>