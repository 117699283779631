export const columns = [
    {
      header: 'Id',
      prop: 'id'
    },
    {
      header: 'Date',
      prop: 'dto',
      type: 'dto'
    },
    {
      header: 'Revenues',
      prop: 'income',
      type: 'number'
    },
    {
      header: 'Dépenses',
      prop: 'expense',
      type: 'number'
    },
    {
      header: 'Hypothèque',
      prop: 'mortgage',
      type: 'number'
    },
    {
      header: 'Solde',
      prop: 'balance',
      type: 'number'
    },
  ];

  