import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';

import * as auth from './auth';
import * as scenario from './scenario';

export interface AppState {
  auth: auth.AuthState;
  scenario: scenario.ScenarioState;
}

export const reducers: ActionReducerMap<any> = {
  auth: auth.reducer,
  scenario: scenario.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: AppState, action: any): AppState => {
    if (
      // !action.silent &&
      environment.log.store
    ) {
      // console.log('\nstate', state);
      // console.log('+ action', action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = (
  !environment.production
  ? [logger] // Dev only meta reducer
  : []); // Production only meta reducer

export const effects = [
  auth.AuthEffects,
  scenario.ScenarioEffects
];

export const services = [];
