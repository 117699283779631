<div class="panel hu-well">

    <div class="panel-container">
        <div class="panel-content">

            <div class="header-container">
                <div class="scenario-half">
                    <div class="primary">
                        <ul>
                            <li>             
                                <div *ngIf="scenarioEx.name !== undefined">
                                    <app-x-editable (response)="updateScenarioTitle($event)" (hide)="isOpened = false" [title]="'Remplacez le nom'"
                                        (pop)="isOpened = true" #trigger="cdkOverlayOrigin" [trigger]="trigger"
                                        cdkOverlayOrigin>{{ scenarioEx.name }}</app-x-editable>
                                </div>
                            </li>
                            <li>
                                <span class="primary-label">INCOME</span>
                                <div class="amount">$ {{ scenarioEx.totalIncome }}</div>
                            </li>
                            <li>
                                <span class="primary-label">EXPENSE</span>
                                <div><span class="">$  {{ scenarioEx.totalExpense }}</span></div>
                            </li>
                            <li>

                            </li>
                        </ul>
                    </div>

                    <div class="secondary">
                        <ul>
                            <li>
                                <span class="sec-label">Rate</span>
                                <div></div>
                            </li>
                            <li>
                                <span class="sec-label">Periode</span>
                                <div></div>
                            </li>
                            <li>
                                <span class="sec-label">Frequency</span>
                                <div>{{ scenarioEx.freq }}</div>
                            </li>
                            <li>
                                <span class="sec-label">Started on</span>
                                <div>{{ scenarioEx.dateDebut }}</div>
                            </li>
                            <li>
                                <div class='btn-group' *ngIf="scenarioEx.id">
                                    <button class="btn btn-default btn-sm" (click)="delete(scenarioEx)">
                                        <i class="fal fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

                <div class="mortgage-half">

                    <app-loan-header></app-loan-header>

                </div>

            </div>
        </div>
    </div>

</div>