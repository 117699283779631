<div class="panel ">

    <div class="panel-hdr">
        <h2>{{ 'L_ELEMENTBOX_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
        <div class="panel-content">

            <form [formGroup]="form" (ngSubmit)="submit(form.value)" class="smart-form">

                <div class="form-group">
                    <label class="form-label">{{ 'L_INFOBOX_SCENARIO_FIELD' | translate }}</label>
                    <div class="select">
                        <select class="form-control" [formControl]="form.controls['scenario']">
                            <option *ngFor="let scneario of (scenarios$ | async)" [value]="scneario.id">
                                {{scneario.name}}</option>
                        </select>
                        <i></i>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">{{ 'L_ELEMENTBOX_TYPE' | translate }}</label>
                    <div class="select">
                        <select class="form-control" formControlName="type">
                            <option value="2">Revenu</option>
                            <option value="5">Dépense</option>
                        </select>
                        <i></i>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">{{ 'L_INFOBOX_BEGINDATE' | translate }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icon-pre fal fa-calendar"></i>
                            </span>
                        </div>
                        
                        <input [matDatepicker]="picker" (click)="picker.open()" class="form-control" formControlName="startsAt" type="text"
                            data-lpignore="true"
                            placeholder="yyyy-MM-dd">
                        <mat-datepicker #picker></mat-datepicker>
                            
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">{{ 'L_ELEMENTBOX_AMOUNT' | translate }}</label>
                    <input class="form-control" formControlName="amount" type="text" data-lpignore="true">
                </div>
                
                <div class="custom-control custom-checkbox">
                    <input id="amortization" type="checkbox" class="custom-control-input" formControlName="amort">
                    <label class="custom-control-label" for="amortization">Étalement</label>
                    <br>
                    <span class="help-block">Montant par année (sera amorti sur plusieurs périodes)</span>
                </div>

                <div class="form-group">
                    <a href="javascript:void(0)" (click)="fset1 = !fset1" *ngIf="!fset1">{{ 'L_INFOBOX_MOREOPTS' |
                        translate }} [+]</a>
                    <a href="javascript:void(0)" (click)="fset1 = !fset1" *ngIf="fset1">{{ 'L_INFOBOX_LESSOPTS' |
                        translate }} [+]</a>
                </div>

                <div class="more" *ngIf="fset1">

                    <div class="form-group">
                        <label class="form-label">À chaque ...</label>
                        <div class="select">
                            <select class="form-control" formControlName="freqAmount">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                            <i></i>
                            <select class="form-control" formControlName="freqType">
                                <option value="d">Jour</option>
                                <option value="w">Semaine</option>
                                <option value="m">Mois</option>
                                <option value="y">Année</option>
                            </select>
                            <i></i>
                        </div>
                    </div>

                </div>

                <section>

                    <div class="btn-group">
                        <button type="button" (click)="closeForm()" class="btn btn-sm btn-warning">{{ 'L_ACTION_CANCEL' | translate }}</button>
                        <button type="submit" class="btn btn-reg btn-default">{{ 'L_ELEMENTBOX_ADD' | translate }}</button>
                    </div>

                </section>

            </form>

        </div>
    </div>
</div>