<content>
    <div class="item-left">

        <article class="col-sm-12">
            <app-loan-form></app-loan-form>
        </article>
        <!--
        <article class="col-sm-12">
            <app-loan-list></app-loan-list>
        </article>
        -->
    </div>

    <div class="item-home-right cards">

        <div *ngFor="let scen of scenarios; let i = index">

            <div class="card card-body hu-scen" (click)="calculateScenario(scen)">

                <div class="row">
                    <img src="assets/img/house0{{(i + 1)}}.png" alt="Example Scenario {{(i + 1)}}" class="house" />

                    <div class="card">
                        <ul>
                            <li>
                                <div><span>Titre de Scenario {{ (i + 1) }}</span></div>
                            </li>
                            <li class="sparks-info">
                                <span class="txt-color-blue">${{ scen.hypotheque | number:'1.0' }}</span>
                            </li>
                            <li>
                                <span class="primary-label">Rate</span>
                                <div><span class="">{{ scen.rate }} %</span></div>
                            </li>
                            <li>

                                <div><span class="">{{ sharedService.trFreq(scen.freq) }}</span></div>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>

    </div>

</content>