<ng-template cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  (detach)="isOpened = false"
  (overlayOutsideClick)="isOpened = false">

  <div #dialog role="dialog" aria-label="Dialog" class="dialog-container popover bottom" [ngClass]="_classMap" >
    <form class="inline-form">
      <div class="arrow"></div>
      <div class="popover-title" *ngIf="title">{{ title }}</div>
      <div class="popover-content">
        <div class="d-flex flex-inline-controls">
          <input type="text" class="form-control input-sm" [(ngModel)]="val" [ngModelOptions]="{standalone: true}">
          <div class="btn-group">
            <button class="btn btn-primary btn-sm" (click)="save($event)">
              <i class="far fa-check" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default btn-sm" (click)="cancel($event)">
              <i class="far fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</ng-template>

<ng-content></ng-content>