import { Periodic } from 'src/models/levsh-types';
import { TransactionType } from '@models';
import { ITransaction } from './itransaction';
import { ElementType } from 'src/models/element-type.enum';

type DateStrMk = {
    dateStr: string;
    dateMk: number;
};

type YearsPeriods = {
    years: number;
    periods: number;
};

export interface ILoan {
    id?: string;
    name: string;
    hypotheque: number;
    years: number;
    freq: string;
    startsAt?: string;
    rate: number;
    transactions: Periodic[] | null;
    rates?: Periodic[] | null;
    lumps?: Periodic[] | null;
    periodics?: Periodic[] | null;
}

/**
 * The divisor related to the frequency
 */
const freqDiv: { [key: string]: number } = {
    w: 52,
    q: 26,
    b: 24,
    m: 12
};

/**
 * The number of days related to the frequency
 */
const freqDays: { [key: string]: number } = {
    w: 7,
    q: 14,
    b: 15.2,
    m: 30.4
};

export class Loan implements Loan, ITransaction {
    id?: string;
    name: string;

    /** The total amount of the mortgage */
    hypotheque: number;
    years: number;
    accountType: ElementType;
    freq: string;
    startsAt?: string;
    endsAt?: string;
    rate = 0;

    coef?: number;
    paymentLeft?: number;
    payments?: number;
    actualRate?: number;

    /**
     * Calculation amount of money left to pay
     */
    private solde?: number;

    /**
     * Calculated string representation of the start date of the loan ex.: 2021-02-24
     */
    dateDebut?: string;

    /**
     * Calculated number representing the starting date of the loan in second (EPOC Style I think)
     */
    private dateDebutMk?: number;

    /**
     * Calculated periodic payment
     */
    private periodic?: number;

    /**
     * Calculated compound rate
     */
    private compoundRate?: number;

    /**
     *
     */
    public transactions?: Periodic[] | null;

    /**
     *
     */
    public rates?: Periodic[] | null;

    /**
     * 
     */
    public lumps?: Periodic[] | null;

    /**
     * 
     */
    public periodics?: Periodic[] | null;

    /**
     * Record of all the dates where keeping the same payment on a rate change
     */
    keepDateArray?: string[];

    /**
     * Record of all the payment left on any given trasactions
     */
    paymentLeftArray?: number[];

    /**
     *
     */
    rows?: any[];
    stats?: any;
    hError?: any;

    constructor(loan: ILoan) {
        console.log(' === LOAN === ', loan);
        this.id = loan.id || null;
        this.hypotheque = loan.hypotheque;
        this.solde = loan.hypotheque;
        this.years = loan.years;
        this.freq = loan.freq;
        this.setName(loan.name);

        if (loan.transactions) {
            this.rates = loan.transactions.filter(x => x.type === TransactionType.InterestChange);
            this.lumps = loan.transactions.filter(x => x.type === TransactionType.ExtraPayment);
            this.periodics = loan.transactions.filter(x => x.type === TransactionType.Payment);
        }

        this.coef = 0;
        this.payments = 0;
        this.paymentLeft = 0;
        this.actualRate = 0;
        this.keepDateArray = [];
        this.accountType = ElementType.Mortgage;
        this.paymentLeftArray = [];
        this.rows = [];
        this.stats = {};
        this.hError = {};

        if (loan.hypotheque === 0) { return; }

        this.setStartDate(loan.startsAt, loan.rate);
    }

    build(): void {
        let rateChanged: number | null;
        let hasLumpsum: number | null;
        let periodicChanged: boolean;
        let interestPaid = 0;
        let totalInterestPaid = 0;
        let capitalPaid = 0;
        let interetFutur = 0;
        let periodic = 0;
        let hasPayment: boolean;
        let dateId: number;
        let dt: string;
        let mk = new Date();
        let trNo = 1;
        let rows = [];

        let leftToPay;
        let nextPayment;
        let prevDto = 0;
        const dtToday = new Date();

        let transacCount = this.calcFreq();
        this.compoundRate = this.findCompoundRate(this.actualRate, this.coef);
        this.periodic = this.findPeriodicPayment(this.solde, this.compoundRate, this.paymentLeft);

        for (let t = 0; t <= (transacCount - 1); t++) {

            const dto = this.getDateFromTransac(t) as DateStrMk;
            dt = dto.dateStr;
            mk = new Date(dto.dateMk);
            this.setPaymentLeft(transacCount - t);
            periodicChanged = false;
            /**
             * Check si on a un changement du montant du paiement pour cette date.
             */
            const periodicElem = inArrayObj(dto.dateMk, prevDto, this.periodics);
            if (periodicElem) {
                this.periodic = periodicElem.amount;
                transacCount = this.autoNewDuree(t);
                periodicChanged = true;
            }

            let transacError = false;
            /**
             * Check si on a un changement de taux d'interet pour cette date.
             */
            const rateElem = inArrayObj(dto.dateMk, prevDto, this.rates);
            if (rateElem) {
                this.actualRate = rateElem.amount;
                this.compoundRate = this.findCompoundRate(this.actualRate, this.coef);

                if (this.keepDateArray && inArray(dt, this.keepDateArray)) {
                    if (this.paymentLeftArray[0]) {
                        this.setPaymentLeft(this.paymentLeftArray[0] + 1);
                    }
                    this.periodic = this.findPeriodicPayment(this.solde, this.compoundRate, this.paymentLeft);
                } else {
                    if ((this.solde * this.compoundRate) * 1.1 > this.periodic) {
                        this.hError.tag = 'L_ERROR_INT_TOOHIGH';
                        transacError = true;
                    }
                    if (!transacError) {
                        transacCount = this.autoNewDuree(t);
                    }
                }
                rateChanged = rateElem.amount;
            } else {
                rateChanged = null;
            }

            /**
             * Check si on a un versement supplementaire pour cette date.
             */
            const lumpElem = inArrayObj(dto.dateMk, prevDto, this.lumps);
            if (lumpElem) {
                this.solde -= lumpElem.amount;
                hasLumpsum = lumpElem.amount;

                if (this.keepDateArray && inArray(dt, this.keepDateArray)) {
                    // this.setPaymentLeft(this.paymentLeftArray[mk] + 1); To fix 2021-02-24
                    this.setPaymentLeft(this.paymentLeftArray[0] + 1);
                    this.periodic = this.findPeriodicPayment(this.solde, this.compoundRate, this.paymentLeft);
                }

            } else {
                hasLumpsum = null;
            }
            interestPaid = this.solde * this.compoundRate;
            capitalPaid = this.periodic - interestPaid;

            if (this.solde - capitalPaid <= 0) {
                capitalPaid = this.solde;
                this.periodic = capitalPaid + interestPaid;
            }

            this.solde -= capitalPaid;
            totalInterestPaid += interestPaid;

            const row = {
                id: trNo++,
                dt,
                mk: dto.dateMk,
                pl: this.paymentLeft,
                pm: this.periodic,
                ip: interestPaid,
                cp: capitalPaid,
                solde: this.solde,
                rc: rateChanged,
                lump: hasLumpsum,
                pc: periodicChanged,
                kd: 0
            };

            if (this.keepDateArray && inArray(dt, this.keepDateArray)) {
                row.kd = 1;
            } else {
                row.kd = 0;
            }

            rows.push(row);

            /**
             * On accumule le montant d'interet qu'on paye posterieurement.
             */
            if (mk > dtToday) {

                interetFutur += interestPaid;
                /**
                 * On va chercher le prochain paiement quand la date d'aujourd'hui est une date anterieur sinon
                 * on prend le premier paiement quand la date du debut est une date futur.
                 */
                if (nextPayment === undefined) {
                    const currentTransac = trNo;
                    if (this.rows === undefined || this.rows[currentTransac] === undefined) {
                        leftToPay = this.solde;
                        nextPayment = this.periodic;
                    } else {
                        leftToPay = this.rows[currentTransac].solde;
                        nextPayment = this.rows[currentTransac].pm;
                    }
                }

            }

            this.endsAt = dto.dateStr;
            prevDto = dto.dateMk;
            if (this.solde <= 0) {
                break;
            }
        }

        this.rows = rows;

        const amort = this.daysBet(this.dateDebutMk, (mk.getTime() / 1000), freqDays[this.freq]);
        this.stats = {
            leftToPay,
            nextPayment,
            intFutur: interetFutur,
            intPaid: totalInterestPaid,
            totalPaid: +this.hypotheque + totalInterestPaid,
            currentPayment: 0, // currentTransac, to fix 2021-02-24
            leftPayInt: interetFutur + parseFloat(leftToPay),
            periodeAmort: amort
        };
    }

    public setId = (loanid: string): void => {
        this.id = loanid;
    }

    setName(loanname: string): void {
        if (loanname === undefined) {
            this.name = 'Pret sans nom';
        } else {
            this.name = loanname;
        }
    }

    /**
     * Determine le montant total de l'hypotheque.
     *
     * Initialise également le solde de départ.
     *
     * @param amount The mortgage capital amoumt
     */
    setLoanAmount(amount: number): void {
        this.hypotheque = amount;
        this.solde = amount;
    }

    calcFreq(): number {
        switch (this.freq) {
            case 'w':
                this.coef = Math.round(((7 / 365) * 100000000)) / 100000000;
                break;
            case 'q':
                this.coef = Math.round(((7 / 182.5) * 100000000)) / 100000000;
                break;
            case 'b':
                this.coef = Math.round(((1 / 24) * 100000000)) / 100000000;
                break;
            case 'm':
                this.coef = Math.round(((1 / 12) * 100000000)) / 100000000;
                break;
        }
        this.paymentLeft = this.payments = freqDiv[this.freq] * this.years;

        return this.payments;
    }

    /**
     * Determine la date de depart du remboursement.
     *
     * @param dt The starting date of the loan
     * @param rate Taux d'interet de depart
     */
    setStartDate(dt: any, rate: any): void {
        const rateAr = this.rates || [];
        const date = (dt !== '' && dt !== undefined) ? new Date(dt) : new Date();
        this.rate = rate;

        this.dateDebut = date.toISOString().slice(0, 10);
        this.dateDebutMk = Math.floor(date.getTime() / 1000);

        if (dt && rate) {
            rateAr.push({ type: 1, dateId: date.getTime(), amount: +rate });
            this.rates = rateAr;
            this.actualRate = rate;
        }
    }

    findCompoundRate(rate: number, coef: number | undefined): number {
        if (coef === undefined) { return -1; }
        let res = Math.pow((1 + ((rate / 100) / 2)), 2);
        res = Math.pow(res, coef) - 1;
        return res;
    }

    getError(): any {
        return this.hError;
    }

    getErrorTag(): string {
        return this.hError.tag;
    }


    /**
     * Retourne le paiement periodique.
     *
     * @return float Paiement periodique.
     */
    findPeriodicPayment(bal: number, compRate: number, payLeft: number): number {
        let perio = 0;
        let coef = 0;

        perio = bal * compRate;
        coef = 1 - Math.pow((1 + compRate), -(payLeft));
        perio = perio / coef;

        return Math.round((perio * 100)) / 100;
    }

    /**
     * Set le nombre de versement total prévu pour le remboursement.
     *
     * @param payLeft Nombre de paiements
     */
    setPaymentLeft(payLeft: number): void {
        this.paymentLeft = payLeft;
    }

    /**
     * Retourne une date calculée selon le numéro de transaction
     *
     * @param int trNo Numéro de transaction
     * @return date Date en format Y-m-d + format epoc
     */
    getDateFromTransac(trNo: number): DateStrMk {
        let mkCurrent = 0;
        let yr = 0;
        // let adjust_div_t = 0;
        let adjustDay;
        let dateObj;

        const datei = this.dateDebutMk;
        dateObj = new Date(this.dateDebut);

        yr = dateObj.getFullYear();

        switch (this.freq) {
            case 'w':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * 7));
                break;
            case 'q':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * 14));
                break;
            case 'm':
                mkCurrent = dateObj.setMonth(dateObj.getMonth() + trNo);
                break;
            case 'b':
                // ToDO: Fix it, the first row is wrong
                if (dateObj.getDate() > 15) {
                    // adjust_div_t = 2;
                    adjustDay = 1;
                } else {
                    // adjust_div_t = 0;
                    adjustDay = dateObj.getDate();
                }

                const dd = new Date(dateObj.setMonth(dateObj.getMonth() + ((trNo - 1) / 2)));
                mkCurrent = dd.setDate(dd.getDate() + ((trNo % 2) === 0 ? 15 : 0));
                break;
        }

        const resultDate = new Date(mkCurrent);

        return { dateStr: resultDate.toISOString().slice(0, 10), dateMk: mkCurrent };

    }

    /**
     * Pour trouver le nombre de paiement restant par rapport au paiement préféré.
     *
     * @return unknown_type
     */
    autoNewDuree(t: number): number {
        let intPaid;
        let capPaid;
        let rest = this.solde;
        let nbr = 0;

        while (rest > 0) {
            intPaid = (rest * (this.actualRate / 100)) / freqDiv[this.freq];
            capPaid = this.periodic - intPaid;
            rest -= capPaid;
            nbr++;
        }

        return (nbr + t);
    }

    /**
     * Retourne le nombre d'année & periodes de la durée de l'amortissement.
     *
     * @param dt_debut Date de debut
     * @param dt_end Date de fin
     * @param freqDays Frequence
     * @return mixed Array du nombre d'années et nombre de periodes
     */
    daysBet(dateDebut: number, dateEnd: number, freqDayCount: number): YearsPeriods {
        const d1 = new Date(dateEnd).getTime();
        const d2 = new Date(dateDebut).getTime();
        const days = Math.abs((d2 - d1) / (60 * 60 * 24));
        const years = Math.floor(days / 365);
        const periodics = (days % 365) / freqDayCount;
        return { years, periods: Math.round(periodics) };
    }
}

function inArrayObj(nameKey: number, prevDto: number, myArray: any[]): any {
    if (myArray !== undefined) {
        for (const elem of myArray) {
            if (elem.dateId > prevDto && elem.dateId <= nameKey) {
                return elem;
            }
        }
    }
    return false;
}

function inArray(nameKey: any, myArray: any[]): any {
    if (myArray === undefined) {
        return false;
    }

    for (const o of myArray) {
        if (o === nameKey) {
            return myArray[o];
        }
    }
    return false;
}

