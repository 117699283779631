import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { RemoveScenario } from '@app/store/scenario';

import { ILoan, Loan } from '../../../../classes/loan';
import { UpdateLoan } from '@app/store/loan';

@Component({
  selector: 'app-loan-header',
  templateUrl: './loan-header.component.html',
  styleUrls: ['./loan-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanHeaderComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  loan: ILoan;
  loanEx: Loan;
  subscription: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.subscription = this.sharedService.loanEx.subscribe((loanEx) => {
      if (loanEx != null) {
        this.loanEx = loanEx;
        this.cd.markForCheck();
      }
    });

    this.sharedService.loan.pipe(
      takeUntil(this.destroy$)
    ).subscribe(loan => this.loan = loan);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  delete() {
    console.log('==== TODO: delete() To be completed ====');
    this.store.dispatch(RemoveScenario(this.loanEx.id));
  }

  updateLoanName(event) {
    console.log('==== TODO: updateLoanTitle() To be completed ====');
    console.log('repsonse', event, this.loan);
    
    const loan = {
      ...this.loan,
      name: event
    }
    this.store.dispatch(UpdateLoan({ id: this.loan.id, changes: loan }));
  }


}
