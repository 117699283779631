  <div *ngIf="loanEx">

    <div class="panel">

      <div class="panel-hdr">
          <h2>{{ 'L_RESULT_TITLE' | translate }}</h2>
      </div>
      <div class="panel-container">
      <div class="panel-content">

          <dl class="hu">
              <dt>{{ 'L_RESULT_SOLDEWCAP' | translate }}</dt>
              <dd class="pleft">{{ loanEx.stats.leftPayInt | number:'1.1-2' }}</dd>
              <dt>{{ 'L_RESULT_SOLDE' | translate }}</dt>
              <dd>{{loanEx.stats.leftToPay | number:'1.1-2' }}</dd>
              <dt>{{ 'L_RESULT_TOTAMOUNT' | translate }}</dt>
              <dd>{{loanEx.stats.totalPaid | number:'1.1-2' }}</dd>
              <dt>{{ 'L_RESULT_TOTINTEREST' | translate }}</dt>
              <dd>{{loanEx.stats.intPaid | number:'1.1-2' }}</dd>
              <dt>{{ 'L_RESULT_INTTOCOME' | translate }}</dt>
              <dd>{{loanEx.stats.intFutur | number:'1.1-2' }}</dd>
              <dt>{{ 'L_RESULT_NEXTPAIE' | translate }}</dt>
              <dd>{{loanEx.stats.nextPayment}}</dd>
              <dt>{{ 'L_RESULT_AMORTPERIOD' | translate }}</dt>
              <dd>{{showAmortization(loanEx.stats.periodeAmort)}}</dd>
              <dt>{{ 'L_RESULT_PAIECOUNT' | translate }}</dt>
              <dd><b>{{loanEx.payments}}</b> / {{(loanEx.payments + 1) - loanEx.stats.currentPayment}} {{ 'L_RESULT_LEFT' | translate }}</dd>
              
          </dl>
        </div>
      </div>
  </div>
          
</div>