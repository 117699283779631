import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { getElements, RemoveElement } from '@app/store/scenario';
import { IScenarioElement } from 'src/models/scenario-element.model';

@Component({
  selector: 'app-element-list',
  templateUrl: './element-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementListComponent {

  elements$: Observable<IScenarioElement[]> = this.store.select(getElements);
  elements: IScenarioElement[] = [];
  elementId: string;

  constructor(
    private store: Store) { }

  view(selloan): void {
    // this.sharedService.changeLoan(selloan);
  }

  remove(element) {
    this.store.dispatch(RemoveElement(element));
  }

}
