import { Periodic } from 'src/models/levsh-types';
import { IScenario, TransactionType } from '@models';
import { ITransaction } from './itransaction';
import { ElementType } from 'src/models/element-type.enum';
import { ILoan, Loan } from './loan';
import { ScenarioElement } from './scenario-element';
import { IScenarioElement } from 'src/models/scenario-element.model';

type DateStrMk = {
    dateStr: string;
    dateMk: number;
};

type YearsPeriods = {
    years: number;
    periods: number;
};

/**
 * The divisor related to the frequency
 */
const freqDiv: { [key: string]: number } = {
    w: 52,
    q: 26,
    b: 24,
    m: 12
};

/**
 * The number of days related to the frequency
 */
const freqDays: { [key: string]: number } = {
    w: 7,
    q: 14,
    b: 15.2,
    m: 30.4
};

export class Scenario {
    id?: string;
    name: string;
    coef?: number;
    freq: string = 'q';
    startsAt?: string;
    endsAt?: string;

    /**
     * Calculated string representation of the start date of the loan ex.: 2021-02-24
     */
    dateDebut?: string;

    /**
     * Calculated number representing the starting date of the loan in second (EPOC Style I think)
     */
    private dateDebutMk?: number;

    /**
     *
     */
    public elements?: IScenarioElement[];

    public mortgages?: ILoan[] | null;
    /**
     *
     */
    rows?: any[];

    totalIncome?: number;
    totalExpense?: number;

    constructor(scenario: IScenario) {
        console.log('=== SCENARIO ===', scenario);
        this.id = scenario.id || null;
        this.name = scenario.name;
        this.startsAt = scenario.startsAt;

        if (scenario.elements) {
            this.mortgages = scenario.elements.filter(x => x.type === ElementType.Mortgage);
            this.elements = scenario.elements.filter(x => x.type === ElementType.Income || x.type === ElementType.Expense);
        }

        if (this.startsAt === undefined) {
            this.startsAt = this.getEarliestDate(scenario.elements);
        }

        console.log('mortgages', this.mortgages);
        this.setStartDate(this.startsAt);
        this.totalExpense = 0;
        this.totalIncome = 0;
    }

    public setId = (loanid: string): void => {
        this.id = loanid;
    }

    setName(loanname: string): void {
        this.name = loanname;
    }


    build(): void {
        let trNo = 1;
        let prevDto = 0;
        let transactions = [];
        let mortgages: Loan[] = [];
        let elements: ScenarioElement[] = [];
        let balance = 0;
        let amounts = {
            expense: 0,
            income: 0,
            loan: []
        };
        let amount = 0;
        let graph = [];
        let mortgage = 0;
        let expense = 0;
        let income = 0;
        
        if (this.mortgages !== undefined) {
            for (const mortgage of this.mortgages) {
                const loanEx = new Loan(mortgage);
                loanEx.build();
                mortgages.push(loanEx);
                this.setLastDate(loanEx.endsAt);
            }
            console.log('built mortgages', mortgages);
        }
        if (this.elements !== undefined) {
            for (const el of this.elements) {
                const incomeEx = new ScenarioElement(el);
                incomeEx.build();
                elements.push(incomeEx);
            }
        }

        const t0 = performance.now();
        
        this.rows = [];
        let transacCount = this.calcDays();
        for (let t = 0; t <= transacCount; t++) {
            const dto = this.getDateFromTransac(t) as DateStrMk;
            const dt = dto.dateStr;
            const mk = new Date(dto.dateMk);
            let loans = [];
            let income = 0;
            let expense = 0;
            let mortgage = 0;

            if (false !== (amounts = inArrayObj(dto.dateMk, prevDto, elements, 'amount'))) {
                balance += -amounts.expense + amounts.income;
                income = amounts.income;
                expense = amounts.expense;
            }
            if (false !== (amounts = inArrayObj(dto.dateMk, prevDto, mortgages, 'pm'))) {
                balance += -amounts.income;
                mortgage = amounts.income;
                loans = amounts.loan;
            }
            graph = [balance, ...loans];
            this.totalIncome += income;
            this.totalExpense += expense + mortgage;
            
            const row = {
                id: trNo++,
                dto,
                balance,
                mortgage,
                graph,
                expense,
                income
            };

            this.rows.push(row);
            prevDto = dto.dateMk;
        }

        const t1 = performance.now();
        console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

        this.totalExpense = Math.round(this.totalExpense / transacCount);
        this.totalIncome = Math.round(this.totalIncome / transacCount);
        // console.log(this.rows);
    }

    calcDays() {
        const date1 = new Date(this.startsAt);
        const date2 = new Date(this.endsAt);
        const diff = Math.abs(date1.getTime() - date2.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return Math.ceil(diffDays / freqDays[this.freq]);
    }

    /**
     * Retourne une date calculée selon le numéro de transaction
     *
     * @param int trNo Numéro de transaction
     * @return date Date en format Y-m-d + format epoc
     */
    getDateFromTransac(trNo: number): DateStrMk {
        let mkCurrent = 0;
        let yr = 0;
        // let adjust_div_t = 0;
        let adjustDay;
        let dateObj;

        const datei = this.dateDebutMk;
        dateObj = new Date(this.dateDebut);

        yr = dateObj.getFullYear();

        switch (this.freq) {
            case 'w':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * 7));
                break;
            case 'q':
                mkCurrent = dateObj.setDate(dateObj.getDate() + (trNo * 14));
                break;
            case 'm':
                mkCurrent = dateObj.setMonth(dateObj.getMonth() + trNo);
                break;
            case 'b':
                // ToDO: Fix it, the first row is wrong
                if (dateObj.getDate() > 15) {
                    // adjust_div_t = 2;
                    adjustDay = 1;
                } else {
                    // adjust_div_t = 0;
                    adjustDay = dateObj.getDate();
                }

                const dd = new Date(dateObj.setMonth(dateObj.getMonth() + ((trNo - 1) / 2)));
                mkCurrent = dd.setDate(dd.getDate() + ((trNo % 2) === 0 ? 15 : 0));
                break;
        }

        const resultDate = new Date(mkCurrent);
        return { dateStr: resultDate.toISOString().slice(0, 10), dateMk: mkCurrent };
    }

    getEarliestDate(elements: any) {
        let dt = '';
        if (elements !== undefined) {
            for (const el of elements) {
                // console.log({ el });
                if (dt === '' || new Date(el.startsAt) < new Date(dt)) {
                    dt = el.startsAt;
                }
            }
        }
        return dt;
    }

    /**
     * Determine la date de fin du scenario
     *
     * @param dt The starting date of the loan
     */
    setLastDate(dt: string): void {
        const date = new Date(dt);
        if (this.endsAt === undefined || new Date(dt) > new Date(this.endsAt)) {
            this.endsAt = dt;
        }
    }

    /**
     * Determine la date de depart du scenario
     * 
     * Search for the oldest date scanning through the elements
     *
     * @param dt The starting date of the loan
     */
    setStartDate(dt: string): void {
        const date = (dt !== '' && dt !== undefined) ? new Date(dt) : new Date();
        this.dateDebut = date.toISOString().slice(0, 10);
        this.dateDebutMk = Math.floor(date.getTime() / 1000);
    }
}

function inArrayObj(nameKey: number, prevDto: number, elements: any[], field): any {
    let balance = {
        expense: 0,
        income: 0,
        loan: []
    };
    if (elements !== undefined && elements.length > 0) {
        for (const [index, elem] of elements.entries()) {
            const amount = amountFrom(nameKey, prevDto, elem.rows, field);
            if (elem.accountType === ElementType.Expense) {
                balance.expense += amount.balance;
            } else {
                balance.income += amount.balance;
            }
            if(elem.accountType === ElementType.Mortgage) {
                balance.loan[index] = amount.solde;
            }
        }
    }
    return balance;
}

function amountFrom(nameKey: number, prevDto: number, myArray, field) {
    let result = {
        balance: 0,
        solde: 0,
    };
    // console.log(nameKey, field);
    if (myArray !== undefined) {
        for (const row of myArray) {
            if (row.mk > prevDto && row.mk <= nameKey) {
                result.balance += row[field];
                result.solde = row['solde'];
            }
            if (row.mk > nameKey) {
                return result;
            }
        }
    }
    return result;
}

function inArray(nameKey: any, myArray: any[]): any {
    if (myArray === undefined) {
        return false;
    }

    for (const o of myArray) {
        if (o === nameKey) {
            return myArray[o];
        }
    }
    return false;
}

