import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './+dashboard/dashboard.component';
import { HomeComponent } from './+home/home.component';
import { MainComponent } from './shared/layout/main/main.component';

const frData = {
    description: 'Le Vrai Simulateur d\'Hypothèque - Restez actif!',
    ogDescription: 'Le Vrai Simulateur d\'Hypothèque - Restez actif!',
    keywords: 'Simulateur, hypothécaire, calculatrice, peuh, hypothèque, loan, simulator, mortgage, vrai, budget, prêt, enregistrez, sauvegardez',
    ogLocale: 'fr',
}

const enData = {
    description: 'The Real Mortgage Simulator - Keep going!',
    ogDescription: 'The Real Mortgage Simulator - Keep going!',
    keywords: 'Simulator, calculator, loan, mortgage, real, budget, save',
    ogLocale: 'en',
}

const zhData = {
    description: '真正的抵押贷款模拟器',
    ogDescription: '真正的抵押贷款模拟器',
    keywords: '模拟器, 计算器, 贷款, 抵押, 真实的, 预算, 保存',
    ogLocale: 'zh',
}

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            { path: '', component: HomeComponent, data: { 
                pageTitle: 'Home',
                ...enData
            } },
            { path: 'dashboard', component: DashboardComponent, data: { pageTitle: 'Dashboard', ...enData } },
        ]
    },
    {
        path: 'fr',
        component: MainComponent,
        children: [
            { path: '', component: HomeComponent, data: { 
                pageTitle: 'Accueil',
                ...frData
            } },
            { path: 'dashboard', component: DashboardComponent, data: { pageTitle: 'Tableau de bord', ...frData } },
        ]
    },
    {
        path: 'zh',
        component: MainComponent,
        children: [
            { path: '', component: HomeComponent, data: { 
                pageTitle: 'Accueil',
                ...zhData
            } },
            { path: 'dashboard', component: DashboardComponent, data: { pageTitle: 'Home', ...zhData } },
        ]
    },
    {
        path: 'en',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
