<div *ngIf="(loans$ | async)">
  <div class="panel">
    <div class="panel-hdr">
      <h2>{{ 'L_MYLOANS_TITLE' | translate }}</h2>
    </div>
    <div class="panel-container">
      <div class="panel-content">

        @for (loan of loans$ | async; track loan.id) {
          <div (click)="view(loan)" class="case" [ngClass]="{'active': loanId === loan.id}">
            <a class="name" href="javascript:void(0)">{{loan.name}}</a><br>
            <span>$ {{loan.hypotheque | number:'1.0-0'}}</span> &nbsp; {{loan.rate |
            number:'1.1-2'}}% &nbsp; {{loan.freq}}
            <span>&nbsp; <a (click)="remove(loan)">remove</a></span>
          </div>
        }

      </div>
    </div>
  </div>
</div>