<nav class="navbar navbar-default footer">
    
  <div class="row">
      <div class="d-flex align-items-center flex-1 info-panel">
        <span class="hidden-md-down fw-700" [innerHTML]="copyright"></span>
        <span>{{ appVersion }} 
          <span> | 
            <i class="fal fa-mobile-android" *ngIf="isMobile"></i>
            <i class="fal fa-desktop" *ngIf="!isMobile"></i>
             {{ userAgent }}
          </span>
        </span>
      </div>
  </div>
  
</nav>