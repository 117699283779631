import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// TODO: #1 Bring the numbers for modification purpose

interface DialogData {
  vers: string;
  interet: string;
  verssup: string;
}

@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss']
})
export class TransactionModalComponent implements OnInit {
  title: string;
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<TransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    fb: UntypedFormBuilder) {

    this.form = fb.group({
      vers: [''],
      interet: [''],
      verssup: [''],
    });
  }

  ngOnInit() {
    if (this.data['row']['pc']) {
      this.form.patchValue({ vers: this.data['row']['pm'] });
    }
  }

  submit(): void {
    if (!this.form.valid) {
    }

    this.dialogRef.close(this.form.value);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
