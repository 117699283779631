import { Injectable } from '@angular/core';

export const TODO = true;

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  config = {
    name: 'LeVSH',
    title: '',
    version: '0.0.2',
    debug: true,
  };

  getConfig(): Object {
    return this.config;
  }
}

export const APP_CONFIG = {
  app: 'LeVSH',
  appName: 'LeVSH',
  user: 'Admin',
  email: 'info@levsh.com',
  twitter: 'levsh',
  avatar: 'avatar-template.png',
  version: '4.0.0',
  bs4v: '4.3',
  copyright: '',
  throttleDelay: 450,
  filterDelay: 150,
  thisDevice: null,
  isMobile: (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase())),
  mobileMenuTrigger: null,
  mobileResolutionTrigger: 992,
  // isWebkit: ((!!window.chrome && !!window.chrome.webstore) === true || Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 === true),
  isChrome: (/chrom(e|ium)/.test(navigator.userAgent.toLowerCase())),
  isIE: ((window.navigator.userAgent.indexOf('Trident/')) > 0 === true),
  debugState: true,
  rippleEffect: true,
  mythemeAnchor: '#mytheme',
  navAnchor: '#js-primary-nav',
  navHooks: '#js-primary-nav > ul.navigation',
  navAccordion: true,
  navSpeed: 500,
  navClosedSign: 'fal fa-angle-down',
  navOpenedSign: 'fal fa-angle-up',
  storeLocally: true,
  jsArray: [],
  userAgent: window.navigator.userAgent.match(/\((.*?)\)/)[1],
  tokenName: 'id_token'
};

