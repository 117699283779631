<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="CdkOverlayOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  (detach)="connectedOverlayDetach()">
  <div #dialog role="dialog" aria-label="Dialog" class="dialog-container">
    <ng-content></ng-content>
    <div>this is hardcoded :D</div>
  </div>
</ng-template>
