import { Action, createReducer, on } from '@ngrx/store';
import { User } from '@models';
import * as AuthActions from './auth.actions';

export interface AuthState {
  loading: boolean;
  error: any;
  user: Partial<User>;
  logged: boolean;
  loggedOnce: boolean;
}

export const initialState: AuthState = {
  loading: false,
  error: null,
  user: {},
  logged: false,
  loggedOnce: false
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.LoginAction, AuthActions.SignupAction, state => ({ ...state, loading: true })),
  on(AuthActions.LogoutAction, AuthActions.NullToken, state => ({ ...state, loading: false, error: null, user: {}, logged: false })),
  on(AuthActions.LoggedOnce, (state, action) => ({ ...state, loggedOnce: action.payload })),
  on(AuthActions.AuthTokenPayload, AuthActions.TokenRestore,
    (state, action) => ({
        ...state,
        error: null,
        loading: false,
        user: {
          id: action.data.id,
          email: action.data.email
        },
        logged: true
      })
  ),
  on(AuthActions.AuthFailure, (state, action) => ({ ...state, error: action.error, loading: false, user: {}, logged: false })),
  on(AuthActions.UserAccount, state => ({ ...state })),
  on(AuthActions.UserAccountSuccess, AuthActions.AuthSuccess,
    (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.user,
      },
      logged: true
    }))
);

export function reducer(state: AuthState, action: Action): any {
  return authReducer(state, action);
}
