import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DygraphsComponent } from '.';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ DygraphsComponent ],
  exports: [DygraphsComponent],
})
export class DygraphsModule { }
