import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class RoutingService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any
  ) { 
      console.log('const');
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const routeData = this.route.firstChild.snapshot.data;
        this.appendRouteMetaData(routeData);
      }
    });
  }

  private appendRouteMetaData(routeData) {
    // Implement any logic that dynamically adds metadata for specific route data, e.g.:
    const renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
    const exampleTag = renderer.createElement('meta');
    
    exampleTag.setAttribute('property', 'og:image:height');
    exampleTag.setAttribute('content', routeData.imageHeight);
    renderer.appendChild(this.document.head, exampleTag);
    console.log(exampleTag);
    // ...
  }
}
