

(window as any).fbAsyncInit = () => {
    FB.init({
      appId            : '286605551472778',
      //autoLogAppEvents : true,
      xfbml            : false,
      version          : 'v2.10'
    });
    FB.AppEvents.logPageView();
};

(function (d, s, id) {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
} (document, 'script', 'facebook-jssdk'));
