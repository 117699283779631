<div *ngIf="isAdjusment()">

    <div class="panel">
        <div class="panel-hdr">
            <h2>{{ 'L_ADJUST_TITLE' | translate }}</h2>
        </div>
   
    <div class="panel-container">
    <div class="panel-content">

        <div *ngIf="loan.transactions">
            <div>
                <div>{{ 'L_ADJUST_RATE' | translate }}</div>
                <div *ngFor="let transaction of loan.transactions">{{ transaction.dateId | date }} 
                    <span *ngIf="transaction.type === 1 || transaction.type === 3">$</span>&nbsp;
                    <span *ngIf="transaction.type === 2">%</span>&nbsp;
                    <span >{{ transaction.amount }}</span>
                    <span><a (click)="remove(transaction)">remove</a></span></div>
                </div>
        </div>
<!--
        <div id="myints" *ngIf="loan.rates">
            <dl>
                <dt>{{ 'L_ADJUST_RATE' | translate }}</dt>
                <dd *ngFor="let key of loan.rates">{{ key }} <span class="label label-warning">{{
                        loan.rates[key] }}%</span></dd>
            </dl>
        </div>

        <div id="myvers" *ngIf="loan.lumps">
            <dl>
                <dt>{{ 'L_ADJUST_LUMP' | translate }}</dt>
                <dd *ngFor="let key of Object.keys(loan.lumps)">{{ key }} <span class="label label-default">{{
                        loan.lumps[key] | currency}}</span></dd>
            </dl>
        </div>

        <div id="myperio" *ngIf="loan.periodics">
            <dl>
                <dt>{{ 'L_ADJUST_FREQ' | translate }}</dt>
                <dd *ngFor="let key of Object.keys(loan.periodics)">{{ key }} <span class="label label-primary">{{
                        loan.periodics[key] | currency}}</span></dd>
            </dl>
        </div>
        -->
    </div>
    </div>
</div>
</div>