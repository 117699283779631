import { Component } from '@angular/core';
import { SharedService } from '@app/services/shared.service';

@Component({
    selector: 'app-add-button',
    template: `<button class="btn-add" [matMenuTriggerFor]="menu">
                    <span class="button__icon"><i class="far fa-plus"></i></span>
                    <span class="button__text">{{ 'L_ADD_BUTTON' | translate }}</span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="toggleLoan()">{{ 'L_ADD_BUTTON_OPT1' | translate }}</button>
                    <button mat-menu-item (click)="toggleElement()">{{ 'L_ADD_BUTTON_OPT2' | translate }}</button>
                </mat-menu>
                    `,
    styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent {
    constructor(
        private sharedService: SharedService
    ) {}

    toggleLoan() {
        this.sharedService.openLoanForm();
    }

    toggleElement() {
        this.sharedService.openElementForm();
    }
}
