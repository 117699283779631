import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init(): Promise<void> {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  get(key: any): any {
    return this.load().then((db: any) => db[key]);
  }

  /**
   * @returns value
   * @param key
   * @param value
   */
  set(key: any, value: any): any {
    return this.load().then((db: any) => {
      db[key] = value;
      return db;
    }).then(this.dump).then((_: any) => value);
  }

  remove(key: any): any {
    return this.load().then((db: any) => {
      db[key] = null;
      delete db[key];
      return db;
    }).then(this.dump);
  }

  dump = (db: any) => {
    return this.storage.set(environment.premont.db, JSON.stringify(db));
  }

  load(): any {
    return this.storage.get(environment.premont.db).then(db => {
      return db ? JSON.parse(db) : {};
    });
  }

}
